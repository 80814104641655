import { Component, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ResultData } from '../models/resultData';
import { GlobalService } from '../services/global.service';
import { successConstants } from '../constants/successConstants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as saveAs from 'file-saver';
import * as moment from 'moment';
declare var $: any;
@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {
Lhi:any;

  @Input() successData:ResultData ;

  lang=successConstants.eng;

  @Input() timeStamp = '';


  @Input() severityScoreCough = ' ';
  @Input() severityScoreCovid = ' ';
  @Input() tblikely = ' ';

  isFirstVisit:boolean=false;
  isFromInvite=true;
  docName='';
  patientName='';
  showScore: boolean = false;
  meterImageUrl='';
  meterImageUrlLHI='';
  Advice:any=null;
  isCovidPlan:boolean=false;
  hospital='';
  userId='';
  assessmentId='';
  encodedAssessmentId='';
  recordId='';
  dateTime:any[]=[];
  constructor(private router:Router,private http: HttpClient,private globalService:GlobalService) {

  }

  ngOnInit(): void {
    this.isFirstVisit= localStorage.getItem('firstVisit')=='true'?true:false;
    console.log("Success Input",this.successData);
    console.log("risk value:",this.successData["data"]["risk"] )
    this.Lhi=this.successData["data"]["cough_risk_score"] ;
    console.log("the tb value",this.tblikely)
    console.log(moment(this.timeStamp).format('D/MM/YYYY'),"date");
    console.log(moment(this.timeStamp).format('HH:mm:ss'),"time");
    moment.utc("2015-10-24 20:00").local()
    this.dateTime[0]=moment.utc(this.timeStamp).local().format('YYYY/MM/DD');
    this.dateTime[1]=moment.utc(this.timeStamp).local().format('HH:mm:ss z');
    this.Advice=this.timeStamp;
    this.patientName=localStorage.getItem("firstname");
    const lang = localStorage.getItem("value");
    //  localStorage.getItem(this.lang['value'])
    if(localStorage.getItem('lang') === "te") {
      this.lang=successConstants.telugu;
    }else if(localStorage.getItem('lang') === "en") {
      this.lang=successConstants.eng;
    }else if(localStorage.getItem('lang') === "fr") {
      this.lang=successConstants.french;
    }else if(localStorage.getItem('lang') === "hi") {
      this.lang=successConstants.hindi;
    }
    else if(localStorage.getItem('lang') === "bn") {
      this.lang=successConstants.bengali;
    }
    console.log(this.successData)
    let score=parseInt(this.severityScoreCough);
    if(localStorage.getItem('plan') === "covid")
  {
    this.isCovidPlan=true;
   }
   if(this.isCovidPlan==true)
   {
   // score=parseInt(this.severityScoreCovid);
   this.setCovidRiskMeter();
   }

    if(score >=0 && score <=1){
      this.meterImageUrl="../assets/images/pdf_images/index-1.png"
    }
    else if(score >=2 && score <=2 ){
      this.meterImageUrl="../assets/images/pdf_images/index+2.png"
    }
    else if(score >=3 && score <=3 ){
      this.meterImageUrl="../assets/images/pdf_images/index+3.png"
    }
    else if(score >=4 && score <=4){
      this.meterImageUrl="../assets/images/pdf_images/index+4.png"
    }
    else if(score >=5 && score <=5){
      this.meterImageUrl="../assets/images/pdf_images/index+5.png"
    }
    else if(score >=6 && score <=6 ){
      this.meterImageUrl="../assets/images/pdf_images/index+6.png"
    }
    else if(score >=7 && score <=7 ){
      this.meterImageUrl="../assets/images/pdf_images/index+7.png"
    }
    else if(score >=8 && score <=8){
      this.meterImageUrl="../assets/images/pdf_images/index+8.png"
    }
    else if(score >=9 && score <=9 ){
      this.meterImageUrl="../assets/images/pdf_images/index+9.png"
    }
    else if(score >=10 && score <=10 ){
      this.meterImageUrl="../assets/images/pdf_images/Index+10.png"
    }
    


    this.isFromInvite= localStorage.getItem('fromreferal')=='true'?true:false;
    this.docName=localStorage.getItem('docName');
    this.hospital=localStorage.getItem('hospital');
    //this.patientName=localStorage.getItem('firstname');

    $('#home1').modal("show");
    if (this.successData) {
      this.showScore = true;
    }
  }
  getLungRisk(){
    return this.getRisktext(this.severityScoreCough);
  }
  getCovidRisk(){
    return this.getRisktext(this.severityScoreCovid);
  }
  getRisktext(score:string){
console.log("score",score)
     if(parseInt(score)>0 &&parseInt(score)<4){
       return "Low";
     }
     if(parseInt(score)>=4 &&parseInt(score)<=7){
      return "Medium";
    }
    if(parseInt(score)>=8 ){
      return "High";
    }

  }
  getCovidAdvice(){
    let risk=this.getCovidRisk();
    if(risk=="Low"){ return 'Please continue to wear a mask, maintain social distance and other necessary precautions.';}
    if(risk=="Medium"){return 'Please seek medical advice if symptoms worsen.  We also recommend you get yourself tested for Covid. ';}
    if(risk=="High"){return 'We advise you to consult a doctor and get a Covid test at the earliest. ';}
  }
  getColor(risk:string)
  {
    if(risk=="Low"){ return 'green';}
    if(risk=="Medium"){return 'orange';}
    if(risk=="High"){return 'red';}

  }
  // Advice
setCovidRiskMeter()
{

  let score=parseInt(this.severityScoreCovid);

  if(score >=0 && score <=1){
    this.meterImageUrl="https://s3.ap-south-1.amazonaws.com/healthcheck.swaasa.ai/assets/images/pdf_images/index-1.png"
  }
  else if(score >=2 && score <=2 ){
    this.meterImageUrl="https://s3.ap-south-1.amazonaws.com/healthcheck.swaasa.ai/assets/images/pdf_images/index+2.png"
  }
  else if(score >=3 && score <=3 ){
    this.meterImageUrl="https://s3.ap-south-1.amazonaws.com/healthcheck.swaasa.ai/assets/images/pdf_images/index+3.png"
  }
  else if(score >=4 && score <=4){
    this.meterImageUrl="https://s3.ap-south-1.amazonaws.com/healthcheck.swaasa.ai/assets/images/pdf_images/index+4.png"
  }
  else if(score >=5 && score <=5){
    this.meterImageUrl="https://s3.ap-south-1.amazonaws.com/healthcheck.swaasa.ai/assets/images/pdf_images/index+5.png"
  }
  else if(score >=6 && score <=6 ){
    this.meterImageUrl="https://s3.ap-south-1.amazonaws.com/healthcheck.swaasa.ai/assets/images/pdf_images/index+6.png"
  }
  else if(score >=7 && score <=7 ){
    this.meterImageUrl="https://s3.ap-south-1.amazonaws.com/healthcheck.swaasa.ai/assets/images/pdf_images/index+7.png"
  }
  else if(score >=8 && score <=8){
    this.meterImageUrl="https://s3.ap-south-1.amazonaws.com/healthcheck.swaasa.ai/assets/images/pdf_images/index+8.png"
  }
  else if(score >=9 && score <=9 ){
    this.meterImageUrl="https://s3.ap-south-1.amazonaws.com/healthcheck.swaasa.ai/assets/images/pdf_images/index+9.png"
  }
  else if(score >=10 && score <=10 ){
    this.meterImageUrl="https://s3.ap-south-1.amazonaws.com/healthcheck.swaasa.ai/assets/images/pdf_images/Index+10.png"
  }

}
  goToHome(){
    $('#home1').modal("hide");
    if(this.isFromInvite){
      let linkAddress= "/?id="+localStorage.getItem('linkCode');
      this.router.navigateByUrl(linkAddress);
    }else{
      this.router.navigateByUrl('/');
    }


  }
  bookAppointment(){
   window.open("https://www.apolloclinic.com/","_blank")
  }
  download() {
		this.downloadFile().subscribe((response: any) => {
      
			let blob:any = new Blob([response], { type: 'application/pdf' });
			const url = window.URL.createObjectURL(blob);
			//window.open(url);
			saveAs(blob, this.patientName+'report'+'.pdf');
			}), (error: any) => console.log('Error downloading the file'),
			() => console.info('File downloaded successfully');
  }

  downloadFile(): any {

    
    let recordId=this.successData['data']['recordId'];
    let userId =this.successData['data']['userId'];
   
    return this.http.get(`${environment.baseUrl}/api/generate_assessment_report?userId=${userId}&recordId=${recordId}&diagnostic_report=False`,{headers: new HttpHeaders().set('accessToken', localStorage.getItem('accessToken')),responseType: 'blob'})
    
    // 'headers':
  }

  redirectToDeepAssessment() {
    $('#home1').modal('hide');

    this.router.navigate(['/deep-assessment']);
  }
  redirectToTbTest() {
    $('#home1').modal('hide');

    this.router.navigate(['/tb-assessment']);
  }


}


// { status: "SUCCESS",
//    data: { timestamp: "2021-04-11 15:15:52",
//     assessmentId: "92b3c93d-8011-402e-a2b8-176ff600c629",
//      symptoms: { shortness_of_breath: 1, cough_at_night: 1, sputum: 0, wheezing: 1, pain_in_chest: 0, frequent_cough: 1 },
//       age: "21",
//       gender: "0",
//        status: "SUCCESS",
//         record_duration: "6.04",
//          cough_pattern: "Normal",
//          risk: "No",
//          cough_sequence_count: 4,
//           dry_sequence_count: 1,
//            wet_sequence_count: 3,
//            severity: "Normal",
//             cough_severity_score: 3 },
//              assessmentId: "92b3c93d-8011-402e-a2b8-176ff600c629" };
