<div class="container" *ngIf="showScore" style="height: 100vh"></div>
<div
  style="
    background: linear-gradient(to right, #01789c, #fbd786, #c6ffdd);
    padding: 0;
  "
  class="modal fade"
  id="home1"
  data-backdrop="static"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-labelledby="home1"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    style="padding: 1rem"
  >
    <div class="modal-content" style="border-radius: 1rem">
      <div class="modal-header" style="border-bottom: 0px !important">
        <button type="button" class="close" (click)="goToHome()">
          <span aria-hidden="true" style="font-size: 40px">&times;</span>
        </button>
      </div>
      <div class="m-3">
        <div class="d-flex justify-content-between">
          <div>
            <img height="30px" src="../../assets/images/Zensark_New-Logo.svg" />
            <div
              class="f12 alignCenter mt-0"
              style="max-width: 202px; margin: 0 auto"
            >
              <!-- Welcome to instant,<br>
              Inexpensive healthcare<br> -->
              <span class="f10" style="font-family: UbuntuBoldItalic"
                >Powered by Swaasa®</span
              >
            </div>
          </div>
          <div>
            <!-- <img height="30px" src="../../assets/images/logo_cough.png"/> -->
          </div>
        </div>
        <div
          *ngIf="isCovidPlan"
          class="mt-3 f20"
          style="color: var(--colorPrimary)"
        >
          Covid Test Results
        </div>
        <!-- <div
          *ngIf="!isCovidPlan"
          class="mt-3 f20"
          style="color: var(--colorPrimary)"
        >
          Lung Test Results
        </div> -->

        <div>
          <p class="f14">
            <!-- Patient: {{ patientName }}<br />
            Date: {{ dateTime["0"] }}<br />
            Time: {{ dateTime["1"] }} IST<br /> -->
            <!-- Al Confidence: 89.78%<br> -->
          </p>
        </div>
        <!-- <div class="f14" *ngIf="isFirstVisit">
          <div class="mx-3 my-1 text-center">
            Congratulations! You have completed your lung health assessment.<br />
            Please wipe your device screen with disinfectant soft cloth.
          </div>
          <br />
          <div class="mx-3 my-1 text-center">
            We appreciate your interest in monitoring your respiratory health.
            <br />Your assessment results are given below.
          </div>
        </div> -->

        <!-- <div class="f14" *ngIf="!isFirstVisit">
          <div class="mx-3 my-1 text-center">
            Thank you for continuing to monitor your lung health.<br />
            Please wipe your device screen with disinfectant soft cloth.<br />
            Your assessment results are given below.
          </div>
        </div> -->
        <br />
        <div
          style="border-radius: 1rem; border: 1px solid #0040da; padding: 1rem"
        >
          <div class="d-flex f20 justify-content-center" style="color: #0040da">
            <!-- <img style="margin-inline-end: 1rem;" height="25px" src="../../assets/images/logo_ai.png"/> -->
            Results
          </div>

          <!-- <div class="f16 mb-4">
            TB Likely? <br />
            <span [ngStyle]="{ color: tblikely === 'No' ? 'green' : 'red' }">{{
              tblikely
            }}</span>
          </div> -->
          <div class="f16 mb-4">
            Lung Health Index <br />
            <span [ngStyle]="{ color: Lhi < 3 ? 'green' : 'red' }">{{
              Lhi < 3 ? "Low" :(Lhi > 3 && Lhi < 7 )? "Medium" :"High"
            }}</span>
          </div>
          <div class="f16 mb-4">
            Advised to take deeper assessment? <br />
            <span [ngStyle]="{ color: (Lhi < 3 ) ? 'green' : 'red' }">{{ (Lhi < 3 ) ? 'No' :'Yes' }}</span>
          </div>
          <span style="text-align: center; font-weight: bold; display: flex">{{(Lhi < 3 ) ? 'your lung health seems fine. you may quit the application.' :''}}
            </span
          >
        </div>
        <!-- <div class="vr"></div> -->
        <!-- <div class="d-flex flex-row meter-block">
          <div>
            <img
              style="height: 50px; margin-inline-end: 1rem; width: fit-content"
              [src]="meterImageUrl"
            />
          </div>
          <div class="d-flex flex-column">
            <div class="f20">
              Lung Health Index:
              <span [ngStyle]="{ color: getColor(getLungRisk()) }">{{
                getLungRisk()
              }}</span>
            </div>
            <div class="f16" style="color: gray;">
              <span *ngIf="getLungRisk()=='Low Risk'">> Good health</span>
              <span *ngIf="getLungRisk()=='Medium Risk'">> Your lungs are restricted</span>
              <span *ngIf="getLungRisk()=='High Risk'">> Your lungs are constricted.</span>
            </div>
          </div>
        </div> -->
        <!-- <div style="height:2rem"></div> -->
        <div *ngIf="isCovidPlan" class="d-flex flex-row meter-block">
          <div>
            <img
              style="height: 50px; margin-inline-end: 1rem; width: fit-content"
              [src]="meterImageUrlLHI"
            />
          </div>
          <div class="d-flex flex-column">
            <div class="f20">
              Covid Risk:
              <span [ngStyle]="{ color: getColor(getCovidRisk()) }"
                >{{ severityScoreCovid }}( {{ getCovidRisk() }} )</span
              >
            </div>
            <div class="f16" style="color: gray">
              <span *ngIf="getCovidRisk() == 'Low Risk'"
                >> No signs of covid</span
              >
              <span *ngIf="getCovidRisk() == 'Medium Risk'"
                >> Unlikely to have covid</span
              >
              <span *ngIf="getCovidRisk() == 'High Risk'"
                >> Signs you have covid</span
              >
            </div>
          </div>
        </div>
        <!-- <div class="vr"></div> -->
        <div class="d-flex f16 justify-content-start">
          <!-- <i
            style="height: 25px; margin-inline-end: 1rem"
            class="bi bi-arrow-down-circle"
          ></i> -->
          <!-- Next Steps - -->
        </div>
        <br />
        <button 
          (click)="redirectToDeepAssessment()"
          style="
            width: max-content;
            background-color: #0040da;
            color: white;
            width: 100%;
            padding: 0.5rem;
            border: none;
          "
        >
          Proceed for Deep Assessment</button
        >
        <!-- <button *ngIf="tblikely==='Yes'"
          (click)="redirectToTbTest()"
          style="
            width: max-content;
            background-color: #f37123;
            color: white;
            width: 100%;
            padding: 0.5rem;
            border: none;
          "
        >
          Proceed for TB Test</button
        > -->
        <br />
        <button
          style="
            width: max-content;
            background-color: transparent;
            border: #0040da solid 1px;
            width: 100%;
            padding: 0.5rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            font-weight: bold;
          "
          (click)="goToHome()"
        >
          Home
        </button>

        <div class="f14 mb-4">
          <span style="color: #0040da"
            >Please wipe your device screen with disinfectant soft cloth.<br /></span
          ><br />
          You may take the assessment again at your convenience or if any of
          your symptoms change.
        </div>
        <!-- <div class="f16 my-3" >{{getCovidAdvice()}}</div> -->

        <!-- <ng-container *ngIf="getCovidRisk()=='High'">
          <div class="f16 my-3" style="color: red ;">
            Quickly repeat this cough test to double check.
          </div>
          <div class="f16 my-3">If the result is the same or similar: </div>
          <div class="f16 mb-4" style="color: red ;">
        • Do a Rapid Antigen Test<br>
        • Or, see a Doctor for a PCR Test<br>
        • Isolate until you receive results.<br>
        • Follow guidelines in your country or workplace.<br>
          </div>
        </ng-container>
        <ng-container *ngIf="getCovidRisk()=='Medium'" >
          <div class="f16 my-3"> This requires regular monitoring. </div>
          <div class="f16 my-3" style="color: orange ;">
            Check your cough every few hours, especially if your risk is rated 5- 6.
          </div>
          <div class="f16 my-3">If it stays at this level or higher after a few checks:</div>
          <div class="f16 mb-4" style="color: orange ;">
            Do a Rapid Antigen Test.
          </div>
        </ng-container>
        <ng-container *ngIf="getCovidRisk()=='Low'">
          <div class="f16 mb-4" >
            Continue to record your cough every now and then to check for changes.<br>
            Or, if you feel any changes, do a quick check.<br>
            Otherwise, all good!
          </div>
        </ng-container> -->
        <!-- <ng-container *ngIf="getLungRisk() == 'High'">
          <div class="f16 my-3" style="color: red">
            We strongly recommend you to consult a physician for further
            evaluation and monitoring. We also suggest you continue to take the
            Swaasa self-assessment periodically.
          </div>
        </ng-container>
        <ng-container *ngIf="getLungRisk() == 'Medium'">
          <div class="f16 my-3" style="color: orange">
            We advise you to take the assessment again after some time and
            preferably consult a doctor too. You may continue to take the Swaasa
            self-assessment periodically.
          </div>
        </ng-container>
        <ng-container *ngIf="getLungRisk() == 'Low'">
          <div class="f16 mb-4" style="color: green">
            Your results indicate low risk. You may take the assessment again at
            your convenience or if any of your symptoms change.
          </div>
        </ng-container> -->

        <!-- <div class="d-flex justify-content-center">
          <button
            type="button"
            (click)="download()"
            class="mt-3 mr-2 alignRight btn btn-primary"
          >
            <i class="bi bi-download">&nbsp;</i>Download
          </button>
          <button type="button" (click)="bookAppointment()" class="mt-3 alignRight btn btn-primary"><i class="bi bi-calendar3"></i>Book Appointment</button>
        </div>
        <div class="my-2 f16">
          To book an appointment
          <a href="https://www.apolloclinic.com/" target="_blank">click here</a>
          or please call us on <a href="tel:1860 500 7788">1860-500-7788</a>
        </div>
        <div class="f10" style="color: gray">
          <div class="mb-2">IMPORTANT:</div>
          <p>
            All assessments provided by Apollo's Ai's are suspected (tentative)
            indications of a possible condition, not an actual diagnosis in the
            medical sense. Suspected (tentative) diagnoses are under no
            circumstances a substitute for personal consultation, diagnosis or
            treatment by a doctor. Apollo apps may not be used to make decisions
            in situations that are considered life-threatening.
          </p>
          <div>© Copyright 2023</div>
          <p>
            Helfie is a Trade Mark Hertie IP Pty Ltd. All Rights Reserved.
          </p>
        </div> -->
      </div>
    </div>
  </div>
</div>
