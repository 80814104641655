<div style="height: 100vh; ">
  <div class="section1">
    <div class="row logoHeader">
      <div class="logo col-12 alignLeft">
        <img src="../../assets/images/logo_cough.png" />
      

        <span> Instant Lung Health Check</span>
      </div>
      <div *ngIf="false" class="logo col-6 alignRight">
        <!-- <img src="../../assets/images/swaasa_logo_small.png" /> -->
        <div>
          <select
            class="countryCodes"
            style="width: fit-content"
            [(ngModel)]="langCode"
            (change)="onChange($event.target.value)"
          >
            <option value="en">English</option>
            <option value="te">Telugu</option>
            <option value="fr">French</option>
            <option value="hi">Hindi</option>
            <option value="bn">Bengali</option>
          </select>
        </div>
      </div>
    </div>

    <div class='page-content'>
       <!-- <div>
        <img height="100px" src="../../assets/images/zini_logi_single.png" />
       </div>
      <div class="text-center">C O U G H</div> -->
      <div class="f16 alignCenter mt-3 mb-3" style="max-width: 300px; margin: 0 auto">
        Analyze your cough to assess your respiratory health.
      </div>
    </div>




    <!-- <div class="row" style="margin-top: 39px">
      <div class="col hl">
        <hr />
      </div>
      <div class="col-auto title3">
        {{ lang["introducing"] }}
        <span style="color: var(--colorPrimary)">{{
          lang["swaasaCoughScanner"]
        }}</span>
      </div>
      <div class="col hl">
        <hr />
      </div>
    </div> -->

    <!-- <div class="f12 alignCenter mt-3" style="max-width: 202px; margin: 0 auto">
      {{ lang["swaasaPlatform"] }}
    </div> -->



    <div class=" alignCenter">
      <!-- <div class="f12 alignCenter mt-3 mb-3" style="max-width: 202px; margin: 0 auto">
        Helfie's Al technologies analyze your cough to assess your respiratory health and detect signs of covid.
      </div> -->
      <img
        style="max-width: -webkit-fill-available; cursor: pointer"
        src="../assets/images/RecordBtn.svg"
        (click)="modal('home1')"
        alt="get started"
        height="120"
      />

      <div
        class="my-3 f16 bold alignCenter"
        (click)="modal('home1')"
        style="cursor: pointer"
      >
        {{ lang["letsGetStarted"] }}
      </div>



    </div>
    <div class="alignCenter">
      <app-footer></app-footer>
      <button  (click)="openPrivacyPage()" class="mt-3 f14 btn btn btn-outline-dark">
        Terms & conditions
      </button>
    </div>


  </div>

  <!-- <div class="section2">
    <div class="detail">
      <p>
        {{ lang["takingSelfAssessment"] }}
      </p>

      <p>
        {{ lang["inTheInterest"] }}
      </p>
      <p>
        {{ lang["keepInMind"] }}
      </p>

      {{
        isPublicLink
          ? lang["swaasaAISeriousInfections"]
          : lang["swaasaAIRespiratoryIssue"]
      }}
    </div>

    <div style="margin-top: 28px; color: black; font-size: 11px">
      {{ lang["byContinuing"]
      }}<span
        ><a href="https://swaasa.ai/privacy-policy/" target="_blank">
          {{ lang["privacyPolicy"] }}
        </a></span
      >
    </div>
  </div> -->
  <!-- <div class="footer">
    <app-footer></app-footer>
  </div> -->
</div>

<!-- home 1 modal    -->

<!-- Button trigger modal
<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
  Launch demo modal
</button> -->

<!-- Modal home1 -->
<div
  class="modal fade"
  id="home1"
  tabindex="-1"
  role="dialog"
  aria-labelledby="home1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="alignCenter f19">Just before we do your test... </div>

        <div class="mt-4 px-3" style="margin-bottom: 72px">
          <div class="mb-4">Please enter your mobile.</div>
          <!-- Country code dropdown -->
          <div class="mb-4">
            <ngx-intl-tel-input style="min-width: 249px;"
              class="countryBoxStyling"
              [selectedCountryISO]="CountryISO.UnitedStates"
              [enableAutoCountrySelect]="false"
              [enablePlaceholder]="true" 
              customPlaceholder="Enter Mobile Number"
              [searchCountryFlag]="true"
              [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]"
              [selectFirstCountry]="false"
              [maxLength]="15"              
              [phoneValidation]="true"
              [separateDialCode]="true"
              name="phone"
              [(ngModel)]="mobileNumberNew"
              [ngModelOptions]="{ updateOn: 'blur' }"
            >
            </ngx-intl-tel-input>
          </div>        
          <div style="text-align: left">
           
            <button type="button" class="btnPrimaryBlue" (click)="submitMobile()">
              Submit
            </button>
          </div>
          
          
          
        
          
          <div class="mb-3 row ml-0 mt-2">
            {{ lang["someTips"] }}
          </div>
          <div class="instruction row">
            <span class="circle col"></span><span class="col" style="padding-left: 8px">{{ lang["findQuietPlace"] }}</span>
          </div>
          <div class="instruction row">
            <span class="circle col"></span><span class="col" style="padding-left: 8px">{{ lang["holdThePhone"] }}</span>
          </div>
          <div class="instruction row">
            <span class="circle col"></span><span class="col" style="padding-left: 8px">{{ lang["aRecording"] }}, {{ lang["pleaseCough"] }}</span>
          </div>
          <div class="instruction row">
            <span class="circle col"></span><span class="col" style="padding-left: 8px">{{ lang["postRecording"] }}</span>
          </div>
        </div>
        
        
        
        <div
          class="mt-4 alignCenter"
          style="z-index: 9999; position: absolute; left: 26%; bottom: 0"
        ></div>
        <!-- <div
          style="position: absolute; z-index: 0; bottom: -93px; right: -26px"
        >
          <img src="../../assets/images/instruction.png" />
        </div> -->
        <div
        style="position: absolute; z-index: 0; bottom: -50px; left: 30px"
      >
      <app-footer></app-footer>
      </div>

      </div>
    </div>
  </div>
</div>

<!-- Modal home2 -->

<div
  class="modal fade"
  id="home2"
  tabindex="-1"
  role="dialog"
  aria-labelledby="home2"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      
      <div class="modal-body">
        <div class="alignCenter f20">
          {{ lang["sinceYoure"] }}
        </div>
        <div class="mt-4 px-3" style="margin-bottom: 72px">
          <div class="mb-3">{{ lang["pleaseEnterFollowing"] }}</div>
          <div class="mb-3">
          <input 
            type="text"
             placeholder="First Name"
              [(ngModel)]="name"
              maxlength="25"
              minlength="3"
              #uname="ngModel"
              onkeydown="return ( event.ctrlKey || event.altKey
            || (64<event.keyCode && event.keyCode<91)
            || (96<event.keyCode && event.keyCode<123)
            || (event.keyCode==8) || (event.keyCode==9)
            || (event.keyCode>34 && event.keyCode<40)
            || (event.keyCode==46) || (event.keyCode==32) )"/>
             
            <!-- <span class="validationCircle"> &#10003;</span> -->
          </div>
          <div class="mb-3">
            <input 
              type="text"
               placeholder="Last Name"
                [(ngModel)]="lastname"
                maxlength="25"
                minlength="3"
                #uname="ngModel"
                onkeydown="return ( event.ctrlKey || event.altKey
              || (64<event.keyCode && event.keyCode<91)
              || (96<event.keyCode && event.keyCode<123)
              || (event.keyCode==8) || (event.keyCode==9)
              || (event.keyCode>34 && event.keyCode<40)
              || (event.keyCode==46) || (event.keyCode==32) )"/>
               
              <!-- <span class="validationCircle"> &#10003;</span> -->
            </div>
          <!-- <div class="mb-3">
            <input type="email" placeholder="E-mail id (Optional)"  (input)="validateEmail()" [(ngModel)]="email"  />
          </div> -->
          <div class="mb-3">
            <input
              type="email"
              placeholder="Email"
              [(ngModel)]="email"
              #uemail="ngModel"
            />
          </div>
          <div *ngIf="uemail.touched && !validateEmail()">
            Email must be valid.
          </div>
          
          <div class="mb-3">
            <input
              type="number"
              placeholder="Age"
              [(ngModel)]="age"
        
              min="18"
              max="110"
              [required]="true"
              #uage="ngModel"
              onkeydown="return ( event.ctrlKey || event.altKey
            || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
            || (95<event.keyCode && event.keyCode<106)
            || (event.keyCode==8) || (event.keyCode==9)
            || (event.keyCode>34 && event.keyCode<40)
            || (event.keyCode==46) )"
            />
          </div>
          <div *ngIf="uage.touched">
            {{validateAge()}}
       </div> 
          <div class="mb-3">
            <!-- <input
             type="text" 
             placeholder="Location" 
             [(ngModel)]="location" 
             [required]="true"
             onkeydown="return ( event.ctrlKey || event.altKey
            || (64>event.keyCode && event.keyCode<91)
            || (96>event.keyCode && event.keyCode<123)
            || (event.keyCode==8) || (event.keyCode==9)
            || (event.keyCode==46) || (event.keyCode==32) ||(47>event.keyCode && event.keyCode<58)
            || (event.keyCode==45) || (event.keyCode==47) ||(event.keyCode==35) )"/> -->
            <!-- <span class="validationCircle"> &#10003;</span> -->
          </div>

          <div class="mb-3">
            <select [(ngModel)]="gender" required>
              <option value="" disabled>Sex</option>
              <option value="Male">{{ lang["male"] }}</option>
              <option value="Female">{{ lang["female"] }}</option>
            </select>
          </div>
          
           <div class="mb-3">
            <!-- <select [(ngModel)]="userId" required>
              <option value="" disabled>Select Your Clinic</option>
              <option value="basavanagudi@apolloclinic.com">{{ lang["bangaloreBasavanagudi"] }}</option>
              <option value="bellandur@apolloclinic.com">{{ lang["bangaloreBellandur"] }}</option>
              <option value="ecity@apolloclinic.com">{{ lang["bangaloreElectroniccity"] }}</option>
              <option value="hsr@apolloclinic.com">{{ lang["bangaloreHsrlayout"] }}</option>
              <option value="indiranagar@apolloclinic.com">{{ lang["bangaloreIndra"] }}</option>
              <option value="jpnagar@apolloclinic.com">{{ lang["bangaloreJpnagar"] }}</option>
              <option value="koramangala@apolloclinic.com">{{ lang["bangaloreKormangala"] }}</option>
              <option value="itpl@apolloclinic.com">{{ lang["bangaloreMarathalli"] }}</option>
              <option value="sarjapur@apolloclinic.com">{{ lang["bangaloreSarjapur"] }}</option>
              
              <option value="annanagar@apolloclinic.com">{{ lang["chennaiAnnanagar"] }}</option>
              <option value="kotturpuram@apolloclinic.com">{{ lang["chennaiKotturpuram"] }}</option>
              <option value="tnagar@apolloclinic.com">{{ lang["chennaiTnagar"] }}</option>
              <option value="valasaravakkam@apolloclinic.com">{{ lang["chennaiValsara"] }}</option>
              <option value="velachery@apolloclinic.com">{{ lang["keralaVelacherry"] }}</option>
              
              <option value="indrapuram@apolloclinic.com">{{ lang["ghaziIndra"] }}</option>
              
              
              
              <option value="asraonagar@apolloclinic.com">{{ lang["hydAs"] }}</option>
              <option value="chandanagar@apolloclinic.com">{{ lang["hydChand"] }}</option>
              <option value="hitechcity@apolloclinic.com">{{ lang["hydKondapur"] }}</option>
              <option value="manikonda@apolloclinic.com">{{ lang["hydManikonda"] }}</option>
              <option value="nizampet@apolloclinic.com">{{ lang["hydNizam"] }}</option>
              <option value="uppal@apolloclinic.com">{{ lang["hydUppal"] }}</option>
              
              <option value="mysore@apolloclinic.com">{{ lang["karMysore"] }}</option>
              
              
              <option value="aundh@apolloclinic.com">{{ lang["puneAundh"] }}</option>
              <option value="kharadi@apolloclinic.com">{{ lang["puneKharadi"] }}</option>
              <option value="nigdi@apolloclinic.com">{{ lang["puneNigdi"] }}</option>
              <option value="vimannagar@apolloclinic.com">{{ lang["puneViman"] }}</option>
              <option value="wanowrie@apolloclinic.com">{{ lang["puneWanouri"] }}</option>
              
              
              <option value="vizag@apolloclinic.com">{{ lang["apVizag"] }}</option>
              
            </select> -->
          </div>
        </div>
        <div
          class="mt-4 alignCenter"
          style="z-index: 9999; position: absolute; left: 26%; bottom: 0"
        >
        <!-- old button -->
          <!-- <button
            type="button"
            class="btnPrimary"
            [disabled]="!name || !name.trim() || validateAge() || location==='' || userId===''|| gender===''"
            (click)="signUp()"
          > -->
          <button
          type="button"
          class="btnPrimary"
          [disabled]="!name || !name.trim() || validateAge() || gender==='' || !validateEmail()"
          (click)="signUp()"
        >
            {{ lang["continue"] }}
          </button>
        </div>
        <div
          style="position: absolute; z-index: 0; bottom: -93px; right: -26px"
        >
          <img src="../../assets/images/instruction.png" />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> -->


<div *ngIf="showLoader" id="overlay">
  <div id="circularG">
    <div id="circularG_1" class="circularG"></div>
    <div id="circularG_2" class="circularG"></div>
    <div id="circularG_3" class="circularG"></div>
    <div id="circularG_4" class="circularG"></div>
    <div id="circularG_5" class="circularG"></div>
    <div id="circularG_6" class="circularG"></div>
    <div id="circularG_7" class="circularG"></div>
    <div id="circularG_8" class="circularG"></div>
  </div>
</div>
