<div style="display: grid; grid-row: 1fr auto; height: 100vh">
  <div>
    <div  *ngIf="pageLoad">
      <div class="logo col-6 alignLeft">
        <!-- <img style="width: 106px;height: unset;" src="../../assets/images/logo-pulmoheal-sml.png"/> -->
      </div>
    </div>
    <div *ngIf="!showSuccess" class="title" style="margin-bottom: 45px;
    margin-top: 50px;
    color: #0040da;
    font-weight: bold;
    text-transform: capitalize;">
      {{ lang["respiratoryAssessment"] }}
    </div>

    <div *ngIf="!showSuccess">
      <!-- <div class="row" *ngIf="!isRecordingApiSuccess">
        <div class="col-8 step1"></div>
        <div class="col-4 step2"></div>
      </div>

      <div class="row" *ngIf="isRecordingApiSuccess">
        <div class="col-4 step3"></div>
        <div class="col-8 step4"></div>
      </div> -->

      <div class="recorder" *ngIf="!isRecordingApiSuccess">
        <div class="title" style="color: #2c2c2c">
          {{ lang["coughRecording"] }}
        </div>
        <div
          class="alignCenter f12 mt-3 mb-4"
          style="color: #a5a5a5"
          *ngIf="!recording"
        >
          {{ lang["pressRecordButton"] }}
        </div>
        <div
          class="alignCenter f12 mt-3 mb-4"
          style="color: #a5a5a5"
          *ngIf="recording && enableStop"
        >
          {{ lang["pressStopRecordButton"] }}
        </div>
        <div
          class="alignCenter f12 mt-3 mb-4"
          style="color: #a5a5a5; height: 17.6px"
          *ngIf="recording && !enableStop"
        ></div>

        <!-------------------------------------------------------



        recorder

    ==================================================================-->

        <div class="waves">
          <div id="time" class="time"></div>
        </div>

        <div class="record_button">
          <img
            (click)="initiateRecording()"
            *ngIf="!recording && !reRecord"
            style="cursor: pointer"
            src="../../assets/images/mic.svg"
          />
          <img
            (click)="stopRecording()"
            *ngIf="recording && !reRecord && enableStop"
            style="cursor: pointer"
            src="../../assets/images/stop.svg"
          />
          <img
            (click)="initiateRecording()"
            *ngIf="reRecord"
            style="cursor: pointer"
            src="../../assets/images/re-record.svg"
          />

          <!-- <div> Start</div> -->
        </div>
      </div>

      <div
        class="recordDone"
        style="position: absolute"
        *ngIf="isRecordingApiSuccess"
      >
        <div class="title" style="color: #2c2c2c">
          {{ lang["coughRecording2"] }}
        </div>
        <div class="alignCenter f12 mt-3 mb-4" style="color: #a5a5a5">
          {{ lang["yourRecording"] }}
        </div>

        <div class="waves" style="background-image: none">
          <div id="time1" class="time">
            <span class="number">{{ this.doneDuration }}</span>
            <span class="unit">
              {{ lang["seconds"] }}
            </span>
          </div>
        </div>
        <div class="alignCenter">{{ lang["done"] }}</div>

        <!-- <img
      src="../../assets/images/done.svg"
      style="position: absolute; right: 0; bottom: 0"
    /> -->
      </div>

      <!-- <div *ngIf="isRecordingApiSuccess" class="questions" >

    <div class="recordDone" style="position: absolute; min-height: 230px;">

        <div class="title" style="color: #2c2c2c;">Question</div>
        <div class="alignCenter f12 mt-3 mb-4" style="color: #a5a5a5;">We have a Questions for you to answer to get an even better Analysis.</div>


        <div class="alignCenter">Please Record first to answer the
            question. We aren’t in a hurry!</div>

        <img src="../../assets/images/qImage.png" style="position: absolute;
        right: 0;
        bottom: 0;"/>

    </div>


</div> -->

      
      <!-- new code for stoptb -->
      <!-- <div *ngIf="isRecordingApiSuccess" style="position: fixed; bottom: 20px;display: flex; justify-content: center; width: 100%; margin-top: 35%;">
        <button
                  *ngIf="!isCovidPlan"
                  type="button"
                  class="btnPrimary"
                  (click)="Submit()"
                >
                  {{ lang["submit"] }}
                </button>
      </div> -->
    </div>

    <div *ngIf="showSuccess">
      <app-success
        [successData]="successData"
        [timeStamp]="advice"
        [severityScoreCough]="severity_ScoreCough"
        [severityScoreCovid]="severity_ScoreCovid"
        [tblikely]="tb_likely"
      ></app-success>
    </div>

    <div *ngIf="showLoader" id="overlay">
      <div id="circularG">
        <div id="circularG_1" class="circularG"></div>
        <div id="circularG_2" class="circularG"></div>
        <div id="circularG_3" class="circularG"></div>
        <div id="circularG_4" class="circularG"></div>
        <div id="circularG_5" class="circularG"></div>
        <div id="circularG_6" class="circularG"></div>
        <div id="circularG_7" class="circularG"></div>
        <div id="circularG_8" class="circularG"></div>
      </div>
    </div>
  </div>

  <div
    *ngIf="!isRecordingApiSuccess"
    class="alignCenter"
    style="margin-top: auto"
  >
  <div style="width: 15%;margin: 0 auto;padding: 2rem 0;">
    <app-footer></app-footer>
  </div>
  </div>
</div>
