import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { AuthComponent } from "./auth/auth.component"
import { LandingComponent } from "./landing/landing.component";
import { RecorderComponent } from "./recorder/recorder.component";
import { DeepAssessmentComponent } from "./deep-assessment/deep-assessment.component";
import { TbAssessmentComponent } from "./tb-assessment/tb-assessment.component";

const routes: Routes = [
    //   {
    //     path: "signup",
    //     loadChildren: () =>
    //       import("./signup/signup.module").then((m) => m.SignupModule),
    //   },
    {
        path: "",
        component: LandingComponent
    },

    {
        path: "auth",
        component: AuthComponent,
    },
    {
        path: "recorder",
        component: RecorderComponent
    },
    {
        path: "deep-assessment",
        component: DeepAssessmentComponent
    },
    {
        path: "tb-assessment",
        component: TbAssessmentComponent
    },


];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            useHash: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
