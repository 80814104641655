import { Component, OnInit } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import getBlobDuration from 'get-blob-duration';
import { Router } from '@angular/router';

import { v4 as uuidv4 } from 'uuid';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { GlobalService } from '../services/global.service';
import { ResultData } from '../models/resultData';
import { recorderConstants } from '../constants/recorderConstants';

declare var $: any;
@Component({
  selector: 'app-recorder',
  templateUrl: './recorder.component.html',
  styleUrls: ['./recorder.component.css'],
})
export class RecorderComponent implements OnInit {
  lang = recorderConstants.eng;
  title = 'micRecorder';
  //Lets declare Record OBJ
  record;
  //Will use this flag for toggeling recording
  recording = false;
  severity_ScoreCough = '';
  severity_ScoreCovid = '';
  tb_likely = '';
  advice = '';
  temprature = '';
  oxySat = '';
  url;
  error;
  intervalId;
  duration;
  isCovidPlan = false;
  reRecord = false;
  isRecordingApiSuccess: boolean = false;
  pageLoad = false;
  showLoader = false;
  doneDuration: any = 0;
  selectedOptions = [];
  selectedMedicalConditions = [];
  verifiedBlob: Blob;
  showSuccess: boolean = false;
  successData: ResultData;
  enableStop: boolean = false;
  questionnaireActive: number = 1;
  tempValue = 'C';
  assessmentId = '';
  recordId = '';
  recId = 'Rec_';
  currentDatetime: any = new Date().toUTCString();
  currentYear: any = new Date().getUTCFullYear();
  currentMonth: any = new Date().getUTCMonth();
  currentDate: any = new Date().getUTCDate();
  currentHours: any = new Date().getUTCHours();
  currentMin: any = new Date().getUTCMinutes();
  currentSec: any = new Date().getUTCSeconds();
  currentMilli: any = new Date().getUTCMilliseconds();
  currentTime: any =
    this.currentYear.toString().substr(-2) +
    parseInt(this.currentMonth + 1) +
    this.currentDate +
    this.currentHours +
    this.currentMin +
    this.currentSec +
    this.currentMilli;
  constructor(
    private domSanitizer: DomSanitizer,
    private globalService: GlobalService,
    private http: HttpClient,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const lang = localStorage.getItem('value');
    //  localStorage.getItem(this.lang['value'])
    if (localStorage.getItem('lang') === 'te') {
      this.lang = recorderConstants.telugu;
    } else if (localStorage.getItem('lang') === 'en') {
      this.lang = recorderConstants.eng;
    } else if (localStorage.getItem('lang') === 'fr') {
      this.lang = recorderConstants.french;
    } else if (localStorage.getItem('lang') === 'hi') {
      this.lang = recorderConstants.hindi;
    } else if (localStorage.getItem('lang') === 'bn') {
      this.lang = recorderConstants.bengali;
    }

    this.pageLoad = true;
    if (localStorage.getItem('plan') === 'covid') {
      this.isCovidPlan = true;
    }

    setTimeout(() => {
      if (!this.isRecordingApiSuccess) {
        var timer = document.getElementById('time');

        timer.innerHTML =
          '<span class="number">00:00</span>' + this.lang.seconds;
      }
    }, 100);
  }

  selectOptions(optionNumber) {
    console.log('Selected', optionNumber);
    if (optionNumber != 7 && this.selectedOptions.indexOf(7) > -1) {
      this.selectedOptions = [];
      this.selectedOptions.push(optionNumber);
      return;
    }
    if (optionNumber == 7) {
      this.selectedOptions = [7];
      return;
    }
    if (this.selectedOptions.indexOf(optionNumber) == -1) {
      this.selectedOptions.push(optionNumber);
    } else {
      this.selectedOptions.splice(
        this.selectedOptions.indexOf(optionNumber),
        1
      );
    }
  }

  selectMedicalCondition(optionNumber) {
    console.log('Selected Med Cond,', optionNumber);
    if (optionNumber != 7 && this.selectedMedicalConditions.indexOf(7) > -1) {
      this.selectedMedicalConditions = [];
      this.selectedMedicalConditions.push(optionNumber);
      return;
    }
    if (optionNumber == 7) {
      this.selectedMedicalConditions = [7];
      return;
    }
    if (this.selectedMedicalConditions.indexOf(optionNumber) == -1) {
      this.selectedMedicalConditions.push(optionNumber);
    } else {
      this.selectedMedicalConditions.splice(
        this.selectedOptions.indexOf(optionNumber),
        1
      );
    }
  }

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
  /**
   * Start recording.
   */
  getCurrentTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const date = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    return `${year}${month}${date}${hours}${minutes}${seconds}`;
  }
  initiateRecording() {
    this.enableStop = false;
    let newAssessmentId = this.recId + this.getCurrentTime();

    let mediaConstraints = {
      video: false,
      audio: true,
    };
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));
    this.assessmentId = newAssessmentId;

    // popup for microphone permission

    // navigator.mediaDevices.getUserMedia({ audio: true })
    // .then(function(stream) {
    //   console.log('You let me use your mic!')
    // })
    // .catch(function(err) {
    //   alert('please enable the microphone to access the application')
    // });
  }
  /**
   * Will be called automatically.
   */
  successCallback(stream) {
    var options = {
      mimeType: 'audio/wav',
      numberOfAudioChannels: 1,
      sampleRate: 44100,
      desiredSampRate: 44100,
      // timeSlice: 50, // pass this parameter
    };
    //Start Actuall Recording
    this.intervalId = this.timer();

    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();

    this.recording = true;
    this.reRecord = false;
  }
  /**
   * Stop recording.
   */
  stopRecording() {
    console.log(this);
    this.recording = false;
    this.record.stop(this.processRecording.bind(this));

    clearInterval(this.intervalId);
    this.reRecord = true;
  }
  /**
   * processRecording Do what ever you want with blob
   * @param  {any} blob Blog
   */
  processRecording(blob) {
    this.assessmentId = '';
    localStorage.setItem('blob', blob);
    this.url = URL.createObjectURL(blob);
    this.verifyCough(blob);
  }
  /**
   * Process Error.
   */
  errorCallback(error) {
    this.error = 'Can not play audio in your browser';
    window.alert('Error. Please check you microphone access.');
  }

  verifyCough(blob) {
    this.showLoader = true;
    var base64String;
    var base64StringWithoutTag;
    var reader = new FileReader();
    reader.readAsDataURL(blob);

    let recId = 'Rec';
    let newPatientId = localStorage.getItem('patientId') || 'defaultPatientId';
    let patientId = newPatientId.replace(/\+/g, '');

    let now = new Date();

    let year = now.getFullYear();
    let month = ('0' + (now.getMonth() + 1)).slice(-2);
    let day = ('0' + now.getDate()).slice(-2);
    let hours = ('0' + now.getHours()).slice(-2);
    let minutes = ('0' + now.getMinutes()).slice(-2);
    let seconds = ('0' + now.getSeconds()).slice(-2);

    let newAssessmentId = `${recId}_${patientId}_${year}_${month}_${day}_${hours}_${minutes}_${seconds}_A`;

    reader.onloadend = () => {
      base64String = reader.result;
      console.log('Base64 String - ', base64String);
      base64StringWithoutTag = base64String.substr(
        base64String.indexOf(',') + 1
      );
    };

    setTimeout(() => {
      if (base64StringWithoutTag) {
        let formData: FormData = new FormData();
        formData.append('coughsoundfile', blob, 'record.wav');
        formData.append('patientId', localStorage.getItem('patientId'));
        formData.append('userId', localStorage.getItem('userId'));
        formData.append('assessmentId', newAssessmentId); // Use the new assessmentId here
        this.http
          .post<any>(`${environment.baseUrl}/api/verifycough`, formData, {
            headers: new HttpHeaders()
              .set('accessToken', localStorage.getItem('accessToken'))
              .set('reportId', localStorage.getItem('reportId')),
          })
          .subscribe(
            (res) => {
              this.showLoader = false;
              console.log(res);

              if (res.data.isValidCough) {
                this.isRecordingApiSuccess = true;
                this.verifiedBlob = blob;
                this.assessmentId = newAssessmentId; // Update the assessmentId with the new value

                getBlobDuration(blob).then((duration) => {
                  this.doneDuration = duration.toFixed(2);
                });
                localStorage.setItem('assessmentId', this.assessmentId);
                this.Submit();
              } else {
                this.toastr.info('', res.data.message, {
                  timeOut: 3000,
                });
              }
            },
            (err) => {
              this.showLoader = false;
              if (err.error.status == 'ERROR') {
                console.log('error');
                this.toastr.error('', err.error.message, {
                  timeOut: 3000,
                });
              }
            }
          );
      }
    }, 1000);
  }

  timer() {
    var seconds = 0;
    var timer = document.getElementById('time');
    if (seconds < 10) {
      // timer.innerHTML = '<span class="number">00:00</span>' + '<span class="unit">Seconds</span>';

      timer.innerHTML =
        '<span class="number">00:0' + seconds++ + '</span>' + this.lang.seconds;
    } else {
      timer.innerHTML =
        '<span class="number">00:' + seconds++ + '</span>' + this.lang.seconds;
    }

    var refreshIntervalId = setInterval(() => {
      if (seconds < 10) {
        // timer.innerHTML = '<span class="number">00:00</span>' + '<span class="unit">Seconds</span>';

        timer.innerHTML =
          '<span class="number">00:0' +
          seconds++ +
          '</span>' +
          this.lang.seconds;
      } else {
        timer.innerHTML =
          '<span class="number">00:' +
          seconds++ +
          '</span>' +
          this.lang.seconds;
      }
      console.log(seconds);
      if (seconds == 5) {
        console.log(seconds);

        this.enableStop = true;
      }
      if (seconds == 11) {
        console.log('Stop Recording', seconds);

        this.stopRecording();
      }
    }, 1000);

    this.duration = seconds;
    return refreshIntervalId;
  }

  next(stepNo) {
    stepNo == 2 ? this.Submit() : this.questionnaireActive++;
  }

  onChangeTemp(value) {
    this.tempValue = value;
  }

  Submit() {
    this.showLoader = true;

    let questioSet = {};
    if (this.isCovidPlan) {
      let oxySat = 0;
      let temp = 0;
      if (!isNaN(parseFloat(this.oxySat))) {
        oxySat = parseFloat(this.oxySat);
      }
      if (!isNaN(parseFloat(this.temprature))) {
        temp = parseFloat(this.temprature);
        if (this.tempValue == 'C') {
          temp = (temp * 9) / 5 + 32;
        }
      }

      questioSet = {
        frequent_cough: this.selectedOptions.indexOf(6) == -1 ? 0 : 1,
        cough_at_night: this.selectedOptions.indexOf(2) == -1 ? 0 : 1,
        sputum: this.selectedOptions.indexOf(3) == -1 ? 0 : 1,
        pain_in_chest: this.selectedOptions.indexOf(5) == -1 ? 0 : 1,
        shortness_of_breath: this.selectedOptions.indexOf(1) == -1 ? 0 : 1,

        wheezing: this.selectedOptions.indexOf(4) == -1 ? 0 : 1,

        sore_throat: this.selectedOptions.indexOf(8) == -1 ? 0 : 1,
        running_nose: this.selectedOptions.indexOf(9) == -1 ? 0 : 1,
        body_pains: this.selectedOptions.indexOf(10) == -1 ? 0 : 1,
        contact_with_covid_positive:
          this.selectedOptions.indexOf(11) == -1 ? 0 : 1,
        diarrhoea: this.selectedOptions.indexOf(12) == -1 ? 0 : 1,
        diminished_sense_of_smell:
          this.selectedOptions.indexOf(13) == -1 ? 0 : 1,
        fever: this.selectedOptions.indexOf(14) == -1 ? 0 : 1,

        oxygen_saturation: oxySat == null || oxySat == 0 ? '' : oxySat,
        temperature_F: temp == null || temp == 0 ? '' : temp,

        lung_disease: this.selectedMedicalConditions.indexOf(1) == -1 ? 0 : 1,
        heart_disease: this.selectedMedicalConditions.indexOf(2) == -1 ? 0 : 1,
        diabetes: this.selectedMedicalConditions.indexOf(3) == -1 ? 0 : 1,
        kidney_problem: this.selectedMedicalConditions.indexOf(4) == -1 ? 0 : 1,
        high_blood_pressure:
          this.selectedMedicalConditions.indexOf(5) == -1 ? 0 : 1,
        major_operations:
          this.selectedMedicalConditions.indexOf(6) == -1 ? 0 : 1,
        weak_immune_system:
          this.selectedMedicalConditions.indexOf(8) == -1 ? 0 : 1,
        stroke: this.selectedMedicalConditions.indexOf(11) == -1 ? 0 : 1,
        pregnancy: this.selectedMedicalConditions.indexOf(9) == -1 ? 0 : 1,
        smoking: this.selectedMedicalConditions.indexOf(101) == -1 ? 0 : 1,
      };
    } else {
      questioSet = {
        frequent_cough: this.selectedOptions.indexOf(6) == -1 ? 0 : 1,
        cough_at_night: this.selectedOptions.indexOf(2) == -1 ? 0 : 1,
        sputum: this.selectedOptions.indexOf(3) == -1 ? 0 : 1,
        pain_in_chest: this.selectedOptions.indexOf(5) == -1 ? 0 : 1,
        shortness_of_breath: this.selectedOptions.indexOf(1) == -1 ? 0 : 1,

        wheezing: this.selectedOptions.indexOf(4) == -1 ? 0 : 1,
      };
    }

    let gender = localStorage.getItem('gender') == 'Male' ? '0' : '1';

    let fformData: FormData = new FormData();
    fformData.append('age', localStorage.getItem('age'));
    fformData.append('patientId', localStorage.getItem('patientId'));
    //fformData.append('uniqueId', localStorage.getItem('uniqueId'));
    fformData.append('userId', localStorage.getItem('userId'));

    fformData.append('gender', gender);
    fformData.append('symptoms', JSON.stringify(questioSet));
    fformData.append('assessmentId', localStorage.getItem('assessmentId')); // const formData = new HttpParams()
    //   .set('coughsoundfile', base64StringWithoutTag)

    this.http
      .post<any>(`${environment.baseUrl}/api/patient_assessment`, fformData, {
        // headers: new HttpHeaders()
        //   .set('accessToken', localStorage.getItem('accessToken'))
        headers: new HttpHeaders({
          accessToken: localStorage.getItem('accessToken'),
          Authorization: 'my-auth-token',
          'Accept-Language': localStorage.getItem('lang')
            ? localStorage.getItem('lang')
            : 'en',
        }),
      })
      .subscribe(
        (res) => {
          this.showLoader = false;
          console.log(res);
          this.showSuccess = true;
          this.successData = res;
          if (this.isCovidPlan) {
            this.advice = res['data']['timestamp']
              ? res['data']['timestamp']
              : '';
            this.severity_ScoreCovid = res['data']['covid_risk_score'];
            this.severity_ScoreCough = res['data']['cough_risk_score'];
            // this.
            this.tb_likely = res['data']['tb_likely'];

            // For hardcoded values
            // this.severity_ScoreCovid= "10"
            // this.severity_ScoreCough= "8"
          } else {
            this.advice = res['data']['timestamp']
              ? res['data']['timestamp']
              : '';
            this.severity_ScoreCovid = '0';
            this.severity_ScoreCough = res['data']['cough_risk_score'];
            this.tb_likely = res['data']['tb_likely'];

            // For hardcoded values
            // this.severity_ScoreCough= "8"
          }
        },
        (err) => {
          this.showLoader = false;
          this.toastr.info('Please try again');
        }
      );
  }

  validateNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 46 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
