// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //Test
baseUrl:"https://kas-api.swaasa.ai:8085"
 // baseUrl: "https://api.swaasa-app.swaasa.ai:8000"
//baseUrl:"https://440a-2406-b400-d4-e57b-cc4a-d5b2-d38-1ef9.ngrok-free.app"
// baseUrl:"https://0c63-2406-b400-d4-4cd-921-ad63-66b4-edbd.ngrok-free.app"
  //health-check test-en"
  // https://healthcheck.swaasa.ai/
  // baseUrl:"https://0c63-2406-b400-d4-4cd-921-ad63-66b4-edbd.ngrok-free.app"

  //Prod
//  baseUrl:"https://api.swaasa-app.swaasa.ai:8000"
  //baseUrl:"https://mateon.sandbox.swaasa.ai:8084"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
