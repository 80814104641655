<div
  style="
    background: linear-gradient(to right, #01789c, #fbd786, #c6ffdd);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  "
>
  <div
    style="
      background-color: white;
      border-radius: 10px;
      padding: 3rem 1rem;
      margin: 2rem 1rem;
      width: 100%;
      max-width: 600px;
    "
  >
    <div style="margin-bottom: 2rem">
      <img height="50px" src="../../assets/images/Zensark_New-Logo.svg" />
      <div>
        <!-- Welcome to instant,<br>
      Inexpensive healthcare<br> -->
        <span class="f10" style="font-family: UbuntuBoldItalic"
          >Powered by Swaasa®</span
        >
      </div>
    </div>
    <div *ngIf="!apisuccess">
      <div *ngIf="loading" style="display: grid">
        <span
          style="
            color: #2f3c48;
            font-weight: bold;
            text-align: center;
            display: flex;
            justify-content: center;
          "
          >Please wait</span
        >
        <div
          style="
            height: 1px;
            background-color: #dddddd;
            margin-bottom: 2rem;
            margin-top: 0.5rem;
          "
        ></div>
        <span style="text-align: center; color: #0040da; margin-bottom: 0.5rem">
          Processing..
        </span>
        <span
          style="
            text-align: center;
            color: #0040da;
            font-size: large;
            margin-bottom: 1rem;
            font-weight: bold;
          "
        >
          Deep Assessment
        </span>
        <div style="height: 2rem"></div>
        <span
          class="loader"
          style="display: flex; justify-content: center; margin: auto"
        ></span>
        <div style="height: 3rem"></div>
        <span
          style="
            color: #2f3c48;
            text-align: center;
            display: flex;
            justify-content: center;
          "
          >Analyzing your lung condition factoring in the symptoms in
          conjunction with your cough audio sample</span
        >
      </div>
      <div *ngIf="!loading">
        <div
          style="
            text-align: center;
            color: #0040da;
            font-size: larger;
            margin-bottom: 1rem;
            font-weight: bold;
          "
        >
          Deep Assessment
        </div>
        <div style="text-align: center; font-size: large; margin-bottom: 2rem">
          Select all the symptoms that you have
        </div>

        <div>
          <div
            *ngFor="let symptom of symptoms; let i = index"
            class="symptom-container"
            [ngClass]="{ selected: symptom.selected }"
            (click)="toggleSelection(i)"
          >
            {{ symptom.name }}
          </div>
          <button
            (click)="sendSymptomsToAPI()"
            style="
              border: none;
              background: #0040da;
              width: 100%;
              padding: 0.5rem;
              color: white;
              font-weight: bold;
              margin-top: 2rem;
            "
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="apisuccess">
      <div>
        <div
          style="border-radius: 1rem; border: 1px solid #0040da; padding: 2rem"
        >
          <div class="d-flex f20 justify-content-center" style="color: #0040da">
            <!-- <img style="margin-inline-end: 1rem;" height="25px" src="../../assets/images/logo_ai.png"/> -->
            Deep Assessment Results
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 2rem;
              margin-bottom: 2rem;
            "
          >
            <div style="display: grid; text-align: center;width: 120px;">
              <span style="font-weight: bold">{{ cough_count }}</span>
              <span style="font-size: smaller">Cough Count</span>
            </div>
            <div style="display: grid; text-align: center;width: 120px;">
              <span style="font-weight: bold">{{ dry_count }}</span>
              <span style="font-size: smaller">Dry Count</span>
            </div>
            <div style="display: grid; text-align: center;width: 120px;">
              <span style="font-weight: bold">{{ wet_count }}</span>
              <span style="font-size: smaller">Wet Count</span>
            </div>
          </div>
          <!-- <div style="display: flex; justify-content: space-between; gap: 2rem">
            <div style="font-size: small; color: #797e84">
              TB Likely? <br />
              <span
                [ngStyle]="{ color: tb_likely === 'No' ? 'green' : 'red' }"
                style="font-weight: bold;font-size: medium;"
                >{{ tb_likely }}</span
              >
            </div>
            <div style="font-size: small; color: #797e84">
              TB score <br />
              <span
                [ngStyle]="{ color: tb_likely === 'No' ? 'green' : 'red' }"
                style="font-weight: bold;font-size: medium;"
                >{{ tb_score < 3 ? "Low" :(tb_score > 3 && tb_score < 7 )? "Medium" :"High"}}</span
              >
            </div>
          </div> -->
          <div style="display: flex; justify-content: space-between; gap: 2rem;margin: 1rem 0;text-align: center;">
            <div class="d-flex flex-row meter-block" style="width: 120px;justify-content: center;">
              <div>
               <img
                style="height: 50px; width: fit-content"
                [src]="meterImageUrl"/>
               </div>
            
    
            </div>
            <div style="font-size: small; color: #797e84;width: 120px;">
              Lung Health Index <br />
              <span
                [ngStyle]="{ color: lhi < 6 ? 'green' : 'red' }"
                style="font-weight: bold;font-size: medium;"
                >{{ lhi < 3 ? "Low" :(Lhi > 3 && Lhi < 7 ) ? "Medium" :"High"}}</span
              >
            </div>
            
            <div style="font-size: small; color: #797e84;width: 120px;">
              LHI score<br />
              <span
                [ngStyle]="{ color: lhi < 6 ? 'green' : 'red' }"
                style="font-weight: bold;font-size: medium;"
                >{{ lhi }}</span
              >
            </div>
          </div>
          <div style="display: flex; justify-content: space-between; gap: 2rem ;margin: 1rem 0;text-align: center;">
            <div style="font-size: small; color: #797e84;width: 120px;">
              Pattern<br />
              <span style="font-weight: bold;font-size: medium;color: black;">{{ pattern }}</span>
            </div>

            <div style="font-size: small; color: #797e84;width: 120px;">
              Severity <br />
              <span style="font-weight: bold;font-size: medium;color: black;">{{ severity }}</span>
            </div>
            <div style="font-size: small; color: #797e84;width: 120px;">
              Underlying Risk <br />
              <span style="font-weight: bold; font-size: medium;color: black;">{{ risk }}</span>
            </div>
          </div>
          <!-- <div style="font-size: small; color: #797e84; margin: 1rem 0;">
            Underlying Risk <br />
            <span style="font-weight: bold; font-size: medium;color: black;">{{ risk }}</span>
          </div> -->
        </div>

        <!-- <div class="d-flex f16 justify-content-start"></div>
        <div class="d-flex flex-row meter-block">
          <div>
           <img
            style="height: 50px;margin-inline-end: 1rem; width: fit-content"
            [src]="meterImageUrl"/>
           </div>
        

        </div> -->
        <br />
        <!-- <button (click)="redirectToOther()" style="width: max-content; background-color: #f37123; color: white; width: 100%;padding: 1rem;border: none;">Proceed for Deep Assessment</button><br/> -->
        <button
          style="
            width: max-content;
            background-color: transparent;
            border: #0040da solid 1px;
            width: 100%;
            padding: 0.5rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            font-weight: bold;
          "
          (click)="goToHome()"
        >
          Home
        </button>

        <div class="f14 mb-4">
          <span style="color: #0040da"
            >Please wipe your device screen with disinfectant soft cloth.<br /></span
          ><br />
          You may take the assessment again at your convenience or if any of
          your symptoms change.
        </div>
      </div>
    </div>
    <!-- Your content goes here -->
  </div>
</div>
