import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
// private patientId:string='';
// private linkCode:string='';
// private fromreferal:boolean=false;
// private isCovidPlan:boolean=false;

// getLinkType(){
//   return this.fromreferal;
// }

// getLinkCode(){
//   return this.linkCode;
// }

// getPatientId(){
//   return this.patientId;
// }
// getPlanType(){
//   return this.isCovidPlan;
// }
// setPatientId(_patientId:string)
// {
//   this.patientId=_patientId;
// }
// setLinkType(formLink:boolean)
// {
//   this.fromreferal=formLink;
// }
// setLinkCode(value:string)
// {
//   this.linkCode=value;
// }

// setplanType(plan:boolean)
// {
//   this.isCovidPlan=plan;
// }
  constructor() { }
}
