export const successConstants = {
    eng: {
        swaasaThanksYou:"Swaasa Thanks You!",
        swaasaRespiratoryAssessment:"Swaasa Respiratory Assessment",
        weAppreciate:"We appreciate your taking the self-assessment. Your participation also helps improve the accuracy of this AI based application and strengthens our efforts in the battle against Covid-19.  Stay safe!",
        ps:"P.S.  Don’t forget to wipe your device screen with a sanitiser.",
        lungHealthIndex:"Lung Health Index:",
        covidRiskScore:"Covid Risk Score:",
        drName:"Dr. Name :",
        patient:"Patient :",
        thankyou:"Thank you for submitting your cough sample. It has been analysed and a detailed report has been sent to your doctor.",
        yourLungHealthIndexIs:"Your lung health index is:",
        yourCovidRiskScoreIs:"Your covid risk score is:",
        pleaseContactDoctor:"Please contact your doctor for further advice and follow up."
    },
    telugu: {
        swaasaThanksYou:"స్వాస ధన్యవాదాలు!",
        swaasaRespiratoryAssessment:"స్వాస శ్వాస సంబంధిత అంచనా",
        weAppreciate:"మీరు స్వీయ-అంచనా తీసుకున్నందుకు మేము అభినందిస్తున్నాము. మీ భాగస్వామ్యం కూడా ఈ AI ఆధారిత అప్లికేషన్ యొక్క ఖచ్చితత్వాన్ని మెరుగుపరచడంలో సహాయపడుతుంది మరియు కోవిడ్-19కి వ్యతిరేకంగా జరిగే యుద్ధంలో మా ప్రయత్నాలను బలోపేతం చేస్తుంది. సురక్షితంగా ఉండండి!",
        ps:"దయచేసి మీ పరికర స్క్రీన్‌ను శానిటైజర్‌తో తుడవడం మర్చిపోవద్దు.",
        lungHealthIndex:"ఊపిరితిత్తుల ఆరోగ్య సూచిక:",
        covidRiskScore:"కోవిడ్ రిస్క్ స్కోర్:",
        drName:"డాక్టర్ పేరు :",
        patient:"రోగి :",
        thankyou:"మీ దగ్గు నమూనాను సమర్పించినందుకు ధన్యవాదాలు. ఇది విశ్లేషించబడింది మరియు మీ వైద్యుడికి వివరణాత్మక నివేదిక పంపబడింది.",
        yourLungHealthIndexIs:"మీ ఊపిరితిత్తుల ఆరోగ్య సూచిక:",
        yourCovidRiskScoreIs:"మీ కోవిడ్ రిస్క్ స్కోర్:",
        pleaseContactDoctor:"దయచేసి తదుపరి సలహా మరియు ఫాలో అప్ కోసం మీ వైద్యుడిని సంప్రదించండి."
    },
    french: {
        swaasaThanksYou:"Swaasa vous remercie!",
        swaasaRespiratoryAssessment:"Évaluation respiratoire Swaasa",
        weAppreciate:"Nous vous remercions de votre auto-évaluation. Votre participation contribue également à améliorer la précision de cette application basée sur l'IA et renforce nos efforts dans la lutte contre Covid-19. Être prudent!",
        ps:"P.S. N'oubliez pas d'essuyer l'écran de votre appareil avec un désinfectant.",
        lungHealthIndex:"Indice de santé pulmonaire:",
        covidRiskScore:"Score de risque Covid:",
        drName:"Nom du docteur :",
        patient:"Patient :",
        thankyou:"Merci d'avoir soumis votre échantillon de toux. Il a été analysé et un rapport détaillé a été envoyé à votre médecin.",
        yourLungHealthIndexIs:"Votre indice de santé pulmonaire est:",
        yourCovidRiskScoreIs:"Votre score de risque covid est:",
        pleaseContactDoctor:"Veuillez contacter votre médecin pour plus de conseils et un suivi."
    },
    hindi: {
        swaasaThanksYou:"स्वासा धन्यवाद!",
        swaasaRespiratoryAssessment:"स्वासा रेस्पिरेटरी असेसमेंट",
        weAppreciate:"हम आपके आत्म-मूल्यांकन की सराहना करते हैं।आपकी भागीदारी इस एआई आधारित एप्लिकेशन की सटीकता में सुधार करने में भी मदद करती है और कोविड -19 के खिलाफ लड़ाई में हमारे प्रयासों को मजबूत करती है। सुरक्षित रहें!",
        ps:"पी.एस. अपने डिवाइस की स्क्रीन को सैनिटाइज़र से पोंछना न भूलें।",
        lungHealthIndex:"फेफड़े का स्वास्थ्य सूचकांक:",
        covidRiskScore:"कोविड जोखिम स्कोर:",
        drName:"डॉ. नाम:",
        patient:"मरीज़:",
        thankyou:"खांसी का नमूना सबमिट करने के लिए धन्यवाद. इसका विश्लेषण किया गया है और एक विस्तृत रिपोर्ट आपके डॉक्टर को भेज दी गई है।.",
        yourLungHealthIndexIs:"आपका फेफड़े का स्वास्थ्य सूचकांक है:",
        yourCovidRiskScoreIs:"आपका कोविड जोखिम स्कोर है:",
        pleaseContactDoctor:"अधिक सलाह के लिए कृपया अपने चिकित्सक से संपर्क करें और अनुवर्ती कार्रवाई करें."
    },
    bengali: {
      swaasaThanksYou:"সোয়াসা আপনাকে ধন্যবাদ!",
      swaasaRespiratoryAssessment:"সোয়াসা শ্বাসযন্ত্রের মূল্যায়ন",
      weAppreciate:"আমরা আপনার স্ব-মূল্যায়ন গ্রহণের প্রশংসা করি। এছাড়াও আপনার অংশগ্রহণ এই AI ভিত্তিক অ্যাপ্লিকেশনটির যথার্থতা উন্নত করতে সাহায্য করে এবং Covid-19 এর বিরুদ্ধে যুদ্ধে আমাদের প্রচেষ্টাকে শক্তিশালী করে। নিরাপদ থাকো!",
      ps:"পুনশ্চ. একটি স্যানিটাইজার দিয়ে আপনার ডিভাইসের স্ক্রিন মুছতে ভুলবেন না।",
      lungHealthIndex:"ফুসফুসের স্বাস্থ্য সূচক:",
      covidRiskScore:"কোভিড ঝুঁকি স্কোর:",
      drName:"ডাঃ নাম:",
      patient:"রোগী :",
      thankyou:"আপনার কাশি নমুনা জমা দেওয়ার জন্য আপনাকে ধন্যবাদ. এটি বিশ্লেষণ করা হয়েছে এবং আপনার ডাক্তারের কাছে একটি বিশদ প্রতিবেদন পাঠানো হয়েছে।",
      yourLungHealthIndexIs:"আপনার ফুসফুসের স্বাস্থ্য সূচক হল:",
      yourCovidRiskScoreIs:"আপনার কোভিড ঝুঁকি স্কোর হল:",
      pleaseContactDoctor:"আরও পরামর্শ এবং অনুসরণের জন্য অনুগ্রহ করে আপনার ডাক্তারের সাথে যোগাযোগ করুন।"
  },
}
