export const recorderConstants = {
    eng: {
        respiratoryAssessment:"Lung Health Assessment",
        coughRecording:"Cough Recording",
        pressRecordButton:"Press the record button to start recording. If there is any disturbance, you can re-record.",
        pressStopRecordButton:"Press the stop button to stop recording.",
        coughRecording2:"Cough Recording",
        yourRecording:"Your recording has been successfully submitted. You can now proceed to the question(s).",
        seconds:"Seconds",
        done:"Done",
        doYouHaveSymptoms:"Do you have any of the below symptoms? (Select all that apply)",
        difficultyWhileBreathing:"Difficulty in breathing",
        coughDuringNight:"Cough during night",
        mucusSputum:"Mucus / Sputum",
        soundInChestWhileBreathing:"Sound in chest while breathing",
        painInChest:"Pain in chest",
        frequentCough:"Frequent cough",
        soreThroat:"sore throat",
        runningNose:"Running nose",
        bodyPain:"body pain",
        ContactWithCovidPositive:"contact with covid positive",
        diarrhoea:"diarrhoea",
        diminishedSenseOfSmell:"diminished sense of smell",
        noSymptoms:"No symptoms",
        next:"Next",
        submit:"Submit",
        doYouHaveAnyFollowingQuestions:"Do you have any of the following medical condition? (Select all that apply)",
        lungDisease:"Lung Disease",
        heartDisease:"Heart Disease",
        diabetes:"Diabetes",
        kidneyProblem:"Kidney Problem",
        highBloodPressure:"High blood pressure",
        majorOperationLikeOrganTransplant:"Major Operation like Organ Transplant",
        stroke:"Stroke",
        weakImmuneSystem:"Weak Immune system",
        pregnancy:"Pregnancy",
        smoking:"Smoking",
        none:"None",
        doYouHaveAnyOfTheFollowingVitals:"Do you have any of the following vitals?",
        temperature:"Temperature",
        oxygenSaturation:"Oxygen Saturation"

    },
    telugu: {
      respiratoryAssessment:"శ్వాసకోశ అంచనా",
      coughRecording:"దగ్గు రికార్డింగ్",
      pressRecordButton:"రికార్డింగ్ ప్రారంభించడానికి రికార్డ్ బటన్‌ను నొక్కండి. ఏదైనా ఆటంకం ఉంటే, మీరు మళ్లీ రికార్డ్ చేయవచ్చు.",
      pressStopRecordButton:"రికార్డింగ్ ఆపడానికి స్టాప్ రికార్డ్ బటన్ నొక్కండి.",
      coughRecording2:"దగ్గు రికార్డింగ్",
      yourRecording:"మీ రికార్డింగ్ విజయవంతంగా సమర్పించబడింది. మీరు ఇప్పుడు ప్రశ్న(ల)కు వెళ్లవచ్చు..",
      seconds:"సెకన్లు",
      done:"పూర్తి",
      doYouHaveSymptoms:"మీకు ఈ క్రింది లక్షణాలు ఏవైనా ఉన్నాయా? (వర్తించే అన్నింటినీ ఎంచుకోండి).",
      difficultyWhileBreathing:"శ్వాస తీసుకోవడంలో ఇబ్బంది",
      coughDuringNight:"రాత్రి సమయంలో దగ్గు",
      mucusSputum:"శ్లేష్మం/కఫం",
      soundInChestWhileBreathing:"శ్వాస తీసుకునేటప్పుడు ఛాతీలో ధ్వని",
      painInChest:"ఛాతీలో నొప్పి",
      frequentCough:"తరచుగా దగ్గు",
      soreThroat:"గొంతు మంట",
      runningNose:"జలుబు",
      bodyPain:"వొళ్ళు నొప్పి",
      ContactWithCovidPositive:"కోవిడ్-19 ఉన్న వ్యక్తితో కలిసి మెలగడం",
      diarrhoea:"అతిసారం",
      diminishedSenseOfSmell:"వాసన తగ్గింది",
      noSymptoms:"లక్షణాలు లేవు",
      next:"తరువాత",
      submit:"సమర్పించండి",
      doYouHaveAnyFollowingQuestions:"మీకు కింది వైద్య పరిస్థితిలో ఏదైనా ఉందా? (వర్తించే అన్నింటినీ ఎంచుకోండి).",
      lungDisease:"ఊపిరితిత్తుల జబ్బు",
      heartDisease:"గుండె వ్యాధి",
      diabetes:"మధుమేహం",
      kidneyProblem:" కిడ్నీ సమస్య",
      highBloodPressure:"అధిక రక్త పోటు",
      majorOperationLikeOrganTransplant:"అవయవ మార్పిడి వంటి ప్రధాన ఆపరేషన్",
      stroke:"స్ట్రోక్",
      weakImmuneSystem:"బలహీనమైన రోగనిరోధక వ్యవస్థ",
      pregnancy:"గర్భం",
      smoking:"ధూమపానం",
      none:"ఏదీ లేదు",
      doYouHaveAnyOfTheFollowingVitals:"మీకు ఈ క్రింది ప్రాణాధారాలు ఏమైనా ఉన్నాయా?",
      temperature:"ఉష్ణోగ్రత",
      oxygenSaturation:"ఆక్సిజన్ సంతృప్తత"

  },
  french: {
    respiratoryAssessment:"Évaluation respiratoire",
    coughRecording:"Enregistrement de la toux",
    pressRecordButton:"Appuyez sur le bouton d'enregistrement pour démarrer l'enregistrement. En cas de perturbation, vous pouvez réenregistrer",
    pressStopRecordButton:"Appuyez sur le bouton d'arrêt pour arrêter l'enregistrement.",
    coughRecording2:"Enregistrement de la toux",
    yourRecording:"Votre enregistrement a été soumis avec succès. Vous pouvez maintenant passer à la ou aux questions.",
    seconds:"Seconds",
    done:"Terminé",
    doYouHaveSymptoms:"Avez-vous l'un des symptômes ci-dessous? (Sélectionnez tout ce qui s'y rapporte)",
    difficultyWhileBreathing:"Difficulté à respirer",
    coughDuringNight:"Toux pendant la nuit",
    mucusSputum:"Mucus / Crachats",
    soundInChestWhileBreathing:"Bruit dans la poitrine en respirant",
    painInChest:"Douleur dans la poitrine",
    frequentCough:"Toux fréquente",
    soreThroat:"gorge irritée",
    runningNose:"nez qui coule",
    bodyPain:"douleur corporelle",
    ContactWithCovidPositive:"contact avec covid positif",
    diarrhoea:"la diarrhée",
    diminishedSenseOfSmell:"diminution de l'odorat",
    noSymptoms:"Aucun symptôme",
    next:"Prochain",
    submit:"Soumettre",
    doYouHaveAnyFollowingQuestions:"Avez-vous l'une des conditions médicales suivantes? (Sélectionnez tout ce qui s'y rapporte)",
    lungDisease:"Les maladies pulmonaires",
    heartDisease:"Cardiopathie",
    diabetes:"Diabète",
    kidneyProblem:"Problème de rein",
    highBloodPressure:"Hypertension artérielle",
    majorOperationLikeOrganTransplant:"Opération majeure comme la transplantation d'organes",
    stroke:"Accident vasculaire cérébral",
    weakImmuneSystem:"Système immunitaire faible",
    pregnancy:"Grossesse",
    smoking:"Fumeur",
    none:"Rien",
    doYouHaveAnyOfTheFollowingVitals:"Avez-vous l'un des signes vitaux suivants ?",
    temperature:"Température",
    oxygenSaturation:"Saturation d'oxygène"

},
hindi: {
    respiratoryAssessment:"श्वसन आकलन",
    coughRecording:"खांसी की रिकॉर्डिंग",
    pressRecordButton:"रिकॉर्डिंग शुरू करने के लिए रिकॉर्ड बटन दबाएं। यदि कोई गड़बड़ी है, तो आप फिर से रिकॉर्ड कर सकते हैं.",
    pressStopRecordButton:"रिकॉर्डिंग बंद करने के लिए स्टॉप बटन दबाएं।.",
    coughRecording2:"खांसी की रिकॉर्डिंग",
    yourRecording:"आपकी रिकॉर्डिंग सफलतापूर्वक सबमिट कर दी गई है। अब आप प्रश्‍नों पर आगे बढ़ सकते हैं।.",
    seconds:"सेकंड",
    done:"किया हुआ",
    doYouHaveSymptoms:"क्या आपको नीचे दिए गए लक्षणों में से कोई भी है? (लागू होने वाले सभी का चयन करें)",
    difficultyWhileBreathing:"सांस लेने में कठिनाई",
    coughDuringNight:"रात में खांसी",
    mucusSputum:"बलगम/थूक",
    soundInChestWhileBreathing:"सांस लेते समय छाती में आवाज आना",
    painInChest:"सीने में दर्द",
    frequentCough:"बार-बार खांसी",
    soreThroat:"गले में खराश",
    runningNose:"बहता नाक",
    bodyPain:"बदन दर्द",
    ContactWithCovidPositive:"कोविड पॉजिटिव से संपर्क करें",
    diarrhoea:"दस्त",
    diminishedSenseOfSmell:"गंध की कमी",
    noSymptoms:"कोई लक्षण नहीं",
    next:"अगला",
    submit:"प्रस्तुत करना",
    doYouHaveAnyFollowingQuestions:"क्या आपको निम्न में से कोई चिकित्सीय स्थिति है? (लागू होने वाले सभी का चयन करें)",
    lungDisease:"फेफड़ों की बीमारी",
    heartDisease:"दिल की बीमारी",
    diabetes:"मधुमेह",
    kidneyProblem:"गुर्दे की समस्या",
    highBloodPressure:"उच्च रक्त चाप",
    majorOperationLikeOrganTransplant:"अंग प्रत्यारोपण जैसे प्रमुख ऑपरेशन",
    stroke:"आघात",
    weakImmuneSystem:"कमजोर प्रतिरक्षा प्रणाली",
    pregnancy:"गर्भावस्था",
    smoking:"धूम्रपान",
    none:"कोई नहीं",
    doYouHaveAnyOfTheFollowingVitals:"क्या आपके पास निम्न में से कोई भी जीवन शक्ति है?",
    temperature:"तापमान",
    oxygenSaturation:"ऑक्सीजन संतृप्ति"

},
bengali: {
  respiratoryAssessment:"শ্বাসযন্ত্রের মূল্যায়ন",
  coughRecording:"কাশি রেকর্ডিং",
  pressRecordButton:"রেকর্ডিং শুরু করতে রেকর্ড বোতাম টিপুন। যদি কোন ঝামেলা হয়, আপনি পুনরায় রেকর্ড করতে পারেন।",
  pressStopRecordButton:"রেকর্ডিং বন্ধ করতে স্টপ বোতাম টিপুন।",
  coughRecording2:"কাশি রেকর্ডিং",
  yourRecording:"আপনার রেকর্ডিং সফলভাবে জমা দেওয়া হয়েছে. আপনি এখন প্রশ্ন(গুলি) এ যেতে পারেন।",
  seconds:"সেকেন্ড",
  done:"সম্পন্ন",
  doYouHaveSymptoms:"আপনার কি নিচের কোন উপসর্গ আছে? (প্রযোজ্য সমস্ত নির্বাচন করুন)",
  difficultyWhileBreathing:"শ্বাস নেওয়ার সময় অসুবিধা",
  coughDuringNight:"রাতে কাশি",
  mucusSputum:"শ্লেষ্মা/থুথু",
  soundInChestWhileBreathing:"শ্বাস নেওয়ার সময় বুকে শব্দ হয়",
  painInChest:"বুকে ব্যাথা",
  frequentCough:"ঘন ঘন কাশি",
  soreThroat:"গলা ব্যথা",
  runningNose:"চলমান নাক",
  bodyPain:"শরীর ব্যাথা",
  ContactWithCovidPositive:"কোভিড পজিটিভের সাথে যোগাযোগ করুন",
  diarrhoea:"ডায়রিয়া",
  diminishedSenseOfSmell:"গন্ধ অনুভূতি হ্রাস",
  noSymptoms:"কোন উপসর্গ নেই",
  next:"পরবর্তী",
  submit:"জমা দিন",
  doYouHaveAnyFollowingQuestions:"আপনার কি নিম্নোক্ত কোন চিকিৎসা অবস্থা আছে? (প্রযোজ্য সমস্ত নির্বাচন করুন)",
  lungDisease:"ফুসফুসের রোগ",
  heartDisease:"হৃদরোগ",
  diabetes:"ডায়াবেটিস",
  kidneyProblem:"কিডনির সমস্যা",
  highBloodPressure:"উচ্চ্ রক্তচাপ",
  majorOperationLikeOrganTransplant:"অঙ্গ প্রতিস্থাপনের মতো বড় অপারেশন",
  stroke:"স্ট্রোক",
  weakImmuneSystem:"দুর্বল ইমিউন সিস্টেম",
  pregnancy:"গর্ভাবস্থা",
  smoking:"ধূমপান",
  none:"কোনোটিই নয়",
  doYouHaveAnyOfTheFollowingVitals:"আপনি নিম্নলিখিত অত্যাবশ্যক কোন আছে?",
  temperature:"তাপমাত্রা",
  oxygenSaturation:"অক্সিজেন সম্পৃক্তি"

},
}
