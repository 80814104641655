import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

interface ApiResponse {
  status: string;
  data: {
    symptoms: {
      active_tb_past: number;
      cough_at_night: number;
      cough_with_sputum_and_blood: number;
      evening_rise_in_temperature: number;
      fatigue: number;
      fever: number;
      frequent_cough: number;
      known_person_having_active_tb: number;
      loss_of_appetite: number;
      pain_in_chest: number;
      shortness_of_breath: number;
      sputum: number;
      sweat_heavy_at_night: number;
      weight_loss: number;
      wheezing: number;
    };
  };
  assessmentId: string;
}
@Component({
  selector: 'app-tb-assessment',
  templateUrl: './tb-assessment.component.html',
  styleUrls: ['./tb-assessment.component.css']
})
export class TbAssessmentComponent{
  loading: boolean = false;
  apisuccess: boolean = false;
  cough_count: number;
  dry_count: number;
  wet_count: number;
  lhi: number;
  pattern: '';
  severity: '';
  risk: '';
  tb_likely: '';
  tb_score:'';

  symptoms = [
    { name: 'Active TB in the past', selected: false },
    { name: 'Cough at Night', selected: false },
    { name: 'Cough with Sputum and Blood', selected: false },
    { name: 'Evening Rise in Temperature', selected: false },
    { name: 'Fatigue', selected: false },
    { name: 'Fever', selected: false },
    { name: 'Frequent Cough', selected: false },
    { name: 'Known Person Having Active TB', selected: false },
    { name: 'Loss of Appetite', selected: false },
    { name: 'Pain in Chest', selected: false },
    { name: 'Shortness of Breath', selected: false },
    { name: 'Sputum', selected: false },
    { name: 'Sweat Heavy at Night', selected: false },
    { name: 'Weight Loss', selected: false },
    { name: 'Wheezing', selected: false },
  ];

  constructor(private http: HttpClient,
    private router: Router,
    ) {}

  toggleSelection(index: number): void {
    this.symptoms[index].selected = !this.symptoms[index].selected;
  }

  sendSymptomsToAPI(): void {
    this.loading = true;
    let gender = localStorage.getItem('gender') == 'Male' ? '0' : '1';

    let fformData: FormData = new FormData();
    fformData.append('age', localStorage.getItem('age'));
    fformData.append('patientId', localStorage.getItem('patientId'));
    //fformData.append('uniqueId', localStorage.getItem('uniqueId'));
    fformData.append('userId', localStorage.getItem('userId'));

    fformData.append('gender', gender);
    // fformData.append('symptoms', JSON.stringify(questioSet));
    fformData.append('assessmentId', localStorage.getItem('assessmentId')); // const formData = new HttpParams()
    //   .set('coughsoundfile', base64StringWithoutTag)
    const symptomsData = this.symptoms.reduce((acc, symptom) => {
      acc[symptom.name.toLowerCase().replace(/ /g, '_')] = symptom.selected
        ? 1
        : 0;
      return acc;
    }, {});

    fformData.append('symptoms', JSON.stringify(symptomsData));
    this.http
      .post<any>(`${environment.baseUrl}/api/patient_assessment`, fformData, {
        // headers: new HttpHeaders()
        //   .set('accessToken', localStorage.getItem('accessToken'))
        headers: new HttpHeaders({
          accessToken: localStorage.getItem('accessToken'),
          Authorization: 'my-auth-token',
          'Accept-Language': localStorage.getItem('lang')
            ? localStorage.getItem('lang')
            : 'en',
        }),
      })
      .subscribe((res) => {
        this.cough_count=res['data']['cough_sequence_count'];
        this.dry_count=res['data']['dry_sequence_count'];
        this.wet_count=res['data']['wet_sequence_count'];
        this.tb_likely=res['data']['tb_likely'];
        this.tb_score=res['data']['tb_RiskScore'];
        this.lhi=res['data']['cough_risk_score'];
        this.pattern=res['data']['spirometry'];
        this.severity=res['data']['severity'];
        this.risk=res['data']['risk'];
        console.log(this.cough_count)
        this.apisuccess = true;
        this.loading = false;
        console.log(res);
      });

    // this.http.post<ApiResponse>('your_api_endpoint_here', { symptoms: symptomsData })
    //   .subscribe(response => {
    //     console.log('API response:', response);
    //   }, error => {
    //     console.error('API error:', error);
    //   });
  }
  goToHome() {
    // $('#home1').modal('hide');

    this.router.navigateByUrl('/');
  }
}

