import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { LandingComponent } from './landing/landing.component';
import { RecorderComponent } from './recorder/recorder.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { SuccessComponent } from './success/success.component';
import { FooterComponent } from './footer/footer/footer.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { DeepAssessmentComponent } from './deep-assessment/deep-assessment.component';
import { TbAssessmentComponent } from './tb-assessment/tb-assessment.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    LandingComponent,
    RecorderComponent,
    SuccessComponent,
    FooterComponent,
    DeepAssessmentComponent,
    TbAssessmentComponent,


  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,MatInputModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    MatFormFieldModule,
    MatSelectModule,
    NgxIntlTelInputModule,

  ],
  providers: [HttpClientModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
