import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

import { GlobalService } from '../services/global.service';
import { v4 as uuidv4 } from 'uuid';
import { landingConstants } from '../constants/landingContants';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';

declare var $: any;
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
export class LandingComponent implements OnInit {
  lang = landingConstants.eng;
  isOtp = false;
  isTimer = false;
  otp: string = '';
  mobile: string = '';
  name = '';
  lastname='';
  email = '';
  age = '';
  isVerifed = false;
  gender = '';
  location = '';
  userId = '';
  isUserExist;
  userDetails = {};
  showLoader = false;
  countryCode = '91';
  langCode = 'en';
  patientId = '';
  uniqueId = '';
  isPublicLink = true;
  display: any;
  intervalId = 0;
  min = '3';
  max = '25';

  mobileNumberNew: any;

  // Country code dropdown
  SearchCountryField = SearchCountryField;
  // TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Qatar];

  constructor(
    private http: HttpClient,
    private router: Router,
    private globalService: GlobalService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.activatedRoute.paramMap.subscribe((data) => {
      console.log('amc route', data['params']['limit']);
    });
    this.activatedRoute.queryParams.subscribe((data) => {
      if (data['id']) {
        this.uniqueId = atob(data['id']);
        //this.globalService.setPatientId(this.patientId);
        // this.globalService.setLinkType(true);
        localStorage.setItem('fromreferal', 'true');
        this.isPublicLink = false;
        // this.globalService.setLinkCode(data["id"]);
        localStorage.setItem('linkCode', data['id']);
      } else if (data['id']) {
        this.patientId = atob(data['id']);
        //this.globalService.setPatientId(this.patientId);
        // this.globalService.setLinkType(true);
        localStorage.setItem('fromreferal', 'true');
        this.isPublicLink = false;
        // this.globalService.setLinkCode(data["id"]);
        localStorage.setItem('linkCode', data['id']);
      } else {
        localStorage.setItem('fromreferal', 'false');
        localStorage.setItem('linkCode', '');
      }
      console.log('patientId ', this.patientId);
      console.log('uniqueId ', this.uniqueId);
    });
  }

  ngOnInit(): void {
    localStorage.setItem('firstVisit', 'false');
    const lang = localStorage.getItem('value');
    //  localStorage.getItem(this.lang['value'])
    if (localStorage.getItem('lang') === 'te') {
      this.langCode = 'te';
      this.lang = landingConstants.telugu;
    } else if (localStorage.getItem('lang') === 'en') {
      this.langCode = 'en';
      this.lang = landingConstants.eng;
    } else if (localStorage.getItem('lang') === 'fr') {
      this.langCode = 'fr';
      this.lang = landingConstants.french;
    } else if (localStorage.getItem('lang') === 'hi') {
      this.langCode = 'hi';
      this.lang = landingConstants.hindi;
    } else if (localStorage.getItem('lang') === 'bn') {
      this.langCode = 'bn';
      this.lang = landingConstants.bengali;
    } else {
      this.langCode = 'en';
      this.lang = landingConstants.eng;
    }
  }

  timer(minute) {
    let seconds: number = minute * 30;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 0;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        console.log('finished');
        this.isTimer = false;
        clearInterval(timer);
      }
    }, 1000);
  }

  modal(modalId) {
    let id = '#' + modalId;

    $(id).modal('show');
  }

  test() {
    console.log(this.mobileNumberNew);

    if (this.mobileNumberNew != undefined && this.mobileNumberNew != null) {
      let numberA = this.mobileNumberNew['e164Number'].replace('+', '');

      console.log(numberA);
    }
  }

  login() {
    console.log(this.mobile);
    this.otp = '';

    if (this.mobileNumberNew != undefined && this.mobileNumberNew != null) {
      // const headers = {
      //   'Content-Type': 'application/x-www-form-urlencoded;Charset=UTF-8',

      // };

      // const body = new FormData();
      // body.append('mobile_number', "+91" + this.mobile)
      let number = this.mobileNumberNew['e164Number'].replace('+', '');
      const body = new HttpParams()
        .set('loginId', decodeURI('%2B' + number))
        .set('patientId', decodeURI('%2B' + number));
      // .set('patientId', decodeURI("%2B"+number))
      // const body = new HttpParams({
      //   fromObject: {
      //     mobile_number: decodeURI("%2B"+this.countryCode + this.mobile)
      //   }
      // })
      // .set('mobile_number', encodeURI("+91" + this.mobile))
      this.showLoader = true;
      this.http
        .post<any>(
          `${environment.baseUrl}/api/patientLogin`,
          decodeURI(body.toString()),
          {
            headers: new HttpHeaders().set(
              'Content-Type',
              'application/x-www-form-urlencoded;charset=UTF-8'
            ),
          }
        )
        .subscribe(
          (res) => {
            if ((res['status'] = 'SUCCESS')) {
              console.log(res);
              this.isOtp = true;
              this.isTimer = true;
              this.showLoader = false;
              this.timer(2);
              // localStorage.setItem('age',res.userDetails?.age);
              // localStorage.setItem('gender',res.userDetails?.age);
              this.toastr.success(
                '',
                'One Time Password(OTP) has been sent successfully',
                { timeOut: 3000 }
              );
            }
          },
          (err) => {
            console.log(err);
            this.showLoader = false;
          }
        );
    }
  }

  validateEmail() {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.email).toLowerCase());
  }

  validateAge() {
    if (!isNaN(parseFloat(this.age))) {
      if (parseFloat(this.age) < 111 && parseFloat(this.age) > 17) {
        return null;
      } else {
        return 'Age must be between 18 to 110';
      }
    } else {
      return 'Age must be between 18 to 110';
    }
  }

  signUp() {
    console.log('hi');
    let patientData;

    if (this.validateAge() || !this.name || !this.name.trim()) {
      return;
    }
    let name = this.name;
    let lastname=this.lastname;
    patientData = {
      firstname: name,
      lastname: lastname,
      age: this.age,
      gender: this.gender,
      location: this.location,
      userId: 'zensark@swaasa.ai',
      patientId: this.patientId,
email: this.email,
      // location:' ',
      pincode: '492001',
      mobileNumber: this.mobileNumberNew['e164Number'],
      // userId:  localStorage.getItem('linkCode').length<1? 'apolloclinic@gmail.com':''
    };
    let postData = JSON.stringify(patientData);
    let post = {
      patientData: postData,
    };

    const body = new HttpParams().set('patientData', postData);
    // .set('lastname', ' ')
    // .set('age', this.age)
    // .set('gender', this.gender)
    // .set('location', ' ')
    // .set('pincode', "492001")

    this.showLoader = true;

    this.http
      .post<any>(
        `${environment.baseUrl}/api/addHealthcheckPatient`,
        patientData,
        {
          headers: new HttpHeaders()

            .set('Content-Type', 'application/json')
            //.set('Content-Type', 'text/plain;charset=UTF-8')
            .set('accessToken', localStorage.getItem('accessToken')),
        }
      )
      .subscribe(
        (res) => {
          this.showLoader = false;

          if ((res['status'] = 'SUCCESS')) {
            console.log(res);
            localStorage.setItem('accessToken', res['accessToken']);
            this.userDetails = res['userDetails'];
            localStorage.setItem('age', res.userDetails?.age);
            localStorage.setItem('gender', res.userDetails?.gender);
            localStorage.setItem('location', res.userDetails?.location);
            localStorage.setItem('userId', res.userDetails?.userId);
            localStorage.setItem('patientId', res.userDetails?.patientId);
            localStorage.setItem('uniqueId', res.userDetails?.uniqueId);
            localStorage.setItem('uniqueId', res.userDetails?.uniqueId);

            //  localStorage.setItem('userId',res.userDetails?.userId);
            localStorage.setItem(
              'plan',
              res.userDetails?.plan ? res.userDetails?.plan : ''
            );
            localStorage.setItem(
              'firstname',
              res.userDetails?.Name
                ? res.userDetails?.Name
                : res.userDetails?.firstname
            );
            localStorage.setItem('firstVisit', 'true');
            // if(res.userDetails?.plan=='covid')
            // {
            //   this.globalService.setplanType(true);
            // }
            // else{
            //   this.globalService.setplanType(false);
            // }

            localStorage.setItem('userId', res.userDetails?.userId);

            let id = '#home2';

            $(id).modal('toggle');
            setTimeout(() => {
              this.router.navigateByUrl('/recorder');
            }, 10);
          }
        },
        (err) => {
          console.log(err);

          this.showLoader = false;
        }
      );
  }

  beginUser() {
    let id = '#home1';

    $(id).modal('toggle');
    if (this.isUserExist) {
      this.router.navigateByUrl('/recorder');
    } else {
      let id = '#home2';

      $(id).modal('show');
    }
  }
  openPrivacyPage() {
    let url = 'https://swaasa.ai/privacy-policy/';
    window.open(url, '_blank');
  }
  submitMobile() {
    const isApolloUrl = window.location.href.includes('healthcheck');
    // make swaasa while prod build
    // local
    if (isApolloUrl) {
      this.verifyMobileOtpWithoutVerification();
    } else {
    }
  }

  verifyMobileOtpWithoutVerification() {
    let number = this.mobileNumberNew['e164Number'].replace('+', '');
    const body = new HttpParams()
      .set('loginId', decodeURI('%2B' + number))
      .set('patientId', decodeURI('%2B' + number))
      .set('rType', 'patient')
      .set('uniqueId', decodeURI(encodeURIComponent(this.uniqueId)))
      .set('sync', 'true')
      .set('userId','zensark@swaasa.ai');

    this.showLoader = true;

    this.http
      .post<any>(
        `${environment.baseUrl}/api/verifyPatientOtp`,
        decodeURI(body.toString()),
        {
          headers: new HttpHeaders().set(
            'Content-Type',
            'application/x-www-form-urlencoded;charset=UTF-8'
          ),
        }
      )
      .subscribe(
        (res) => {
          this.showLoader = false;

          if (res['status'] === 'SUCCESS') {
            console.log(res);
            this.isOtp = true;
            localStorage.setItem('accessToken', res['accessToken']);
            this.isVerifed = true;
            this.isUserExist = res['userDetails'] == null ? false : true;
            localStorage.setItem('age', res.userDetails?.age);
            localStorage.setItem('gender', res.userDetails?.gender);
            localStorage.setItem('location', res.userDetails?.location);
            localStorage.setItem('userId', res.userDetails?.userId);
            localStorage.setItem('patientId', res.userDetails?.patientId);
            localStorage.setItem('reportId', res.userDetails?.reportId);
            localStorage.setItem('uniqueId', res.userDetails?.uniqueId);
            localStorage.setItem(
              'firstname',
              res.userDetails?.Name
                ? res.userDetails?.Name
                : res.userDetails?.firstname
            );
            localStorage.setItem(
              'plan',
              res.userDetails?.plan ? res.userDetails?.plan : ''
            );

            let docName = '';
            let hospital = '';
            if (res['userDetails']) {
              if (res['userDetails']['userData']) {
                if (res['userDetails']['userData']['firstname']) {
                  docName = res['userDetails']['userData']['firstname'];
                }
                if (res['userDetails']['userData']['hospital']) {
                  hospital = res['userDetails']['userData']['hospital'];
                }
              }
            }
            localStorage.setItem('docName', docName);
            localStorage.setItem('hospital', hospital);
            this.beginUser();
          }
        },
        (err) => {
          console.log(err);
          this.showLoader = false;
          if (err.error.status == 'ERROR') {
            console.log('error');

            this.toastr.error('', err.error.message, {
              timeOut: 3000,
            });
          }
        }
      );
  }

  onChange(value) {
    this.langCode = value;
    localStorage.setItem('lang', value);
    // alert(value)
    const lang = localStorage.getItem('value');
    //  localStorage.getItem(this.lang['value'])
    if (localStorage.getItem('lang') === 'te') {
      this.lang = landingConstants.telugu;
    } else if (localStorage.getItem('lang') === 'en') {
      this.lang = landingConstants.eng;
    } else if (localStorage.getItem('lang') === 'fr') {
      this.lang = landingConstants.french;
    } else if (localStorage.getItem('lang') === 'hi') {
      this.lang = landingConstants.hindi;
    } else if (localStorage.getItem('lang') === 'bn') {
      this.lang = landingConstants.bengali;
    }
  }
}
