export const landingConstants = {
  eng: {
    helpUsFight:"Help Us Fight",
    welcomeTo:"Welcome to",
    againstCovid19:"Against Covid-19",
    swaasaRespiratoryAssessment:"Swaasa Respiratory Assessment",
    introducing:"Introducing",
    swaasaCoughScanner:"Swaasa Cough Scanner",
    swaasaPlatform:"Swaasa platform analyses your cough sound to assess your respiratory health.",
    letsGetStarted:"Let's get checked",
    takingSelfAssessment:"Taking self assessment at home is for your own well being. Hence it is important that you follow the instructions carefully to get genuine results.",
    inTheInterest:"In the interest of your own health and the health of our family and colleagues it is required that the cough sample recorded is your own cough and not any other person’s.",
    keepInMind:"Keep in mind that the system’s usefulness and effectiveness is dependent on the data submitted and correct answers and authentic cough samples of your own is what will give the most benefit to you and your family members.",
    swaasaAISeriousInfections:"Swaasa AI is unique in its screening process as it assesses respiratory health using audiometric analysis of the cough sounds. Often symptoms for Covid-19 are related to respiratory function, making Covid-19, predominantly, a respiratory disease. Assess your respiratory health regularly while also helping us enhance our platform that uses cough sounds, symptoms, and other contextual information to screen for possible COVID-19 and other serious infections.",
    swaasaAIRespiratoryIssue:"Swaasa AI is unique in its screening process as it assesses respiratory health using audiometric analysis of the cough sounds. Covid-19 predominantly impacts the respiratory system. Swaasa uses AI based cough analysis to assess your cough severity and provide an indication of any respiratory issue.",
    byContinuing:"By continuing you agree to Swaasa’s conditions of Use &",
    privacyPolicy:"Privacy Policy",
    beforeWeGoAnyFurther:"Before we go any further…",
    pleaseEnter:"Please enter the following to proceed :",
    otp:"OTP",
    sendOTP:"Send OTP",
    resendOTP:"Resend OTP",
    someTips:"Some tips to keep in mind while recording:",
    findQuietPlace:"Find a quiet place. Ensure while recording there is no background noise like wind noise, fan and other such noises.",
    holdThePhone:"Hold the phone 4-8 inches from your mouth.",
    aRecording:"A recording of 10 seconds will have an adequate number of cough sequences",
    pleaseCough:"Please cough multiple times.",
    eachTime:"Each time take a breath and cough.",
    repeatThis:"Repeat this at least 2 to 3 times.",
    postRecording:"Post recording, wipe the phone with a soft cloth dipped in disinfectant.",
    sinceYoure:"Since you’re here for the first time..",
    pleaseEnterFollowing:"Please enter the following to get started :",
    male:"Male",
    female:"Female",
    continue:"Continue",
    bangaloreBasavanagudi:"BANGALORE-BASAVANAGUDI",
    bangaloreBellandur:"BANGALORE-BELLANDUR",
    bangaloreElectroniccity:"BANGALORE-ELECTRONIC CITY",
    bangaloreHsrlayout:"BANGALORE-HSR LAYOUT",
    bangaloreIndra:"BANGALORE-INDIRANAGAR",
    bangaloreJpnagar:"BANGALORE-JPNAGAR",
    bangaloreKormangala:"BANGALORE-KORAMANGALA",
    bangaloreMarathalli:"BANGALORE-MARATHAHALLI",
    bangaloreSarjapur:"BANGALORE-SARJAPUR ROAD",
    chennaiAnnanagar:"CHENNAI-ANNA NAGAR",
    chennaiKotturpuram:"CHENNAI-KOTTURPURAM",
    chennaiTnagar:"CHENNAI-T'NAGAR",
    chennaiValsara:"CHENNAI-VALASARAVAKKAM",
    ghaziIndra:"GHAZIABAD-INDIRAPURAM",
    hydAs:"HYD-A.S. RAO NAGAR",
    hydChand:"HYD-CHANDA NAGAR",
    hydKondapur:"HYD-HITECH CITY",
    hydManikonda:"HYD-MANIKONDA",
    hydNizam:"HYD-NIZAMPET",
    hydUppal:"HYD-UPPAL",
    karMysore:"MYSORE-KALIDASA ROAD",
    puneAundh:"PUNE-AUNDH",
    puneKharadi:"PUNE-KHARADI",
    puneNigdi:"PUNE-NIGDI(PIMPRI)",
    puneViman:"PUNE-VIMAN NAGAR",
    puneWanouri:"PUNE-WANOURI",
    keralaVelacherry:"CHENNAI-VELACHERY",
    apVizag:"VISHAKAPATNAM-VIZAG"
    
  },
  telugu: {
    helpUsFight:"మాకు పోరాడటానికి సహాయం చేయండి",
    welcomeTo:"స్వాగతం",
    againstCovid19:"కోవిడ్-19కి వ్యతిరేకంగా",
    swaasaRespiratoryAssessment:"స్వాస శ్వాస సంబంధిత అంచనా",
    introducing:"పరిచయం చేస్తోంది",
    swaasaCoughScanner:"స్వాస దగ్గు స్కానర్",
    swaasaPlatform:"స్వాస ప్లాట్‌ఫారమ్ మీ దగ్గు ధ్వనిని విశ్లేషించి మీ శ్వాసకోశ ఆరోగ్యాన్ని అంచనా వేస్తుంది",
    letsGetStarted:"ప్రారంభిద్దాం",
    takingSelfAssessment:"మీ స్వంత శ్రేయస్సు కోసం ఇంట్లో స్వీయ అంచనా తీసుకోండి. కాబట్టి మీరు సరైన ఫలితాలను పొందడానికి సూచనలను జాగ్రత్తగా అనుసరించడం ముఖ్యం.",
    inTheInterest:"మీ స్వంత ఆరోగ్యం దృష్ట్యా మరియు మీ కుటుంబం మరియు సహోద్యోగుల ఆరోగ్యం దృష్ట్యా నమోదు చేయబడిన దగ్గు నమూనా మీ స్వంత దగ్గు మాత్రమే అయివుండాలి ఏ ఇతర  వ్యక్తులది కాదు.",
    keepInMind:"సిస్టమ్ యొక్క ఉపయోగం మరియు ప్రభావం మీరు సమర్పించిన డేటాపై ఆధారపడి ఉంటుందని గుర్తుంచుకోండి మరియు సరైన సమాధానాలు మరియు మీ స్వంత ప్రామాణికమైన దగ్గు నమూనాలు మీకు మరియు మీ కుటుంబ సభ్యులకు అత్యంత ప్రయోజనాన్ని ఇస్తాయని గుర్తుంచుకోండి.",
    swaasaAISeriousInfections:"స్వాసా AI దాని స్క్రీనింగ్ ప్రక్రియలో ప్రత్యేకమైనది, ఎందుకంటే ఇది  మీ దగ్గు శబ్దాలను ఆడియోమెట్రిక్ విశ్లేషణను ఉపయోగించి  మీ శ్వాసకోశ ఆరోగ్యాన్ని అంచనా వేస్తుంది. తరచుగా కోవిడ్-19 లక్షణాలు శ్వాసకోశ పనితీరుకు సంబంధించినవి, ఇవి కోవిడ్-19ని ప్రధానంగా శ్వాసకోశ వ్యాధిగా మారుస్తాయి. మీ శ్వాసకోశ ఆరోగ్యాన్ని క్రమం తప్పకుండా అంచనా వేయండి అలాగే సాధ్యమయ్యే COVID-19 మరియు ఇతర తీవ్రమైన ఇన్ఫెక్షన్‌లను పరీక్షించడానికి దగ్గు శబ్దాలు, లక్షణాలు మరియు ఇతర సందర్భోచిత సమాచారాన్ని ఉపయోగించే మా ప్లాట్‌ఫారమ్‌ను మెరుగుపరచడంలో కూడా మాకు సహాయపడండి.",
    swaasaAIRespiratoryIssue:"స్వాసా AI దాని స్క్రీనింగ్ ప్రక్రియలో ప్రత్యేకమైనది, ఎందుకంటే ఇది  మీ దగ్గు శబ్దాలను ఆడియోమెట్రిక్ విశ్లేషణను ఉపయోగించి  మీ శ్వాసకోశ ఆరోగ్యాన్ని అంచనా వేస్తుంది.కోవిడ్-19 ప్రధానంగా శ్వాసకోశ వ్యవస్థపై ప్రభావం చూపుతుంది. స్వాసా  మీ దగ్గు తీవ్రతను అంచనా వేయడానికి మరియు ఏదైనా శ్వాస సంబంధిత సమస్య యొక్క సూచనను అందించడానికి AI ఆధారిత దగ్గు విశ్లేషణను ఉపయోగిస్తుంది ",
    byContinuing:"కొనసాగించడం ద్వారా మీరు అంగీకరిస్తున్నారు స్వాసా యొక్క వినియోగ షరతులు మరియు",
    privacyPolicy:"గోప్యతా విధానం",
    beforeWeGoAnyFurther:"మనం మరింత ముందుకు వెళ్ళే ముందు…",
    pleaseEnter:"దయచేసి నమోదు చెయ్యండి :",
    otp:"OTP",
    sendOTP:"OTP పంపండి",
    resendOTP:"OTP ను మళ్ళీ పంపండి",
    someTips:" రికార్డింగ్ చేసేటప్పుడు గుర్తుంచుకోవలసిన కొన్ని చిట్కాలు:",
    findQuietPlace:"నిశ్శబ్ద స్థలాన్ని కనుగొనండి(రికార్డ్ చేస్తున్నప్పుడు గాలి శబ్దం, ఫ్యాన్ మరియు అలాంటి ఇతర శబ్దాలు ఏమియు లేవని నిర్ధారించుకోండి).",
    holdThePhone:"మీ నోటి నుండి 4-8 అంగుళాల దూరంలో ఫోన్‌ని పట్టుకోండి..",
    aRecording:"మీ 10 సెకన్ల రికార్డింగ్‌లో తగిన సంఖ్యలో దగ్గు సీక్వెన్సులు ఉంటాయి:-",
    pleaseCough:"దయచేసి అనేక సార్లు దగ్గండి.",
    eachTime:"ప్రతిసారీ శ్వాస తీసుకొని దగ్గండి.",
    repeatThis:"దీన్ని కనీసం 2 నుండి 3 సార్లు పునరావృతం చేయండి..",
    postRecording:"రికార్డింగ్ తర్వాత, క్రిమిసంహారిణిలో ముంచిన మృదువైన గుడ్డతో ఫోన్‌ను తుడవండి.",
    sinceYoure:"మీరు ఉన్నందున(ఇక్కడ మొదటిసారి)..",
    pleaseEnterFollowing:"దయచేసి ప్రారంభించడానికి ముందు క్రింది వాటిని నమోదు చేయండి: :",
    male:"పురుషుడు",
    female:"స్త్రీ",
    continue:"కొనసాగించు",
    bangaloreBasavanagudi:"BANGALORE-BASAVANAGUDI",
    bangaloreBellandur:"BANGALORE-BELLANDUR",
    bangaloreElectroniccity:"BANGALORE-ELECTRONIC CITY",
    bangaloreHsrlayout:"BANGALORE-HSR LAYOUT",
    bangaloreIndra:"BANGALORE-INDIRANAGAR",
    bangaloreJpnagar:"BANGALORE-JPNAGAR",
    bangaloreKormangala:"BANGALORE-KORAMANGALA",
    bangaloreMarathalli:"BANGALORE-MARATHAHALLI",
    bangaloreSarjapur:"BANGALORE-SARJAPUR ROAD",
    chennaiAnnanagar:"CHENNAI-ANNA NAGAR",
    chennaiKotturpuram:"CHENNAI-KOTTURPURAM",
    chennaiTnagar:"CHENNAI-T'NAGAR",
    chennaiValsara:"CHENNAI-VALASARAVAKKAM",
    ghaziIndra:"GHAZIABAD-INDIRAPURAM",
    hydAs:"HYD-A.S. RAO NAGAR",
    hydChand:"HYD-CHANDA NAGAR",
    hydKondapur:"HYD-KONDAPUR",
    hydManikonda:"HYD-MANIKONDA",
    hydNizam:"HYD-NIZAMPET",
    hydUppal:"HYD-UPPAL",
    karMysore:"MYSORE-KALIDASA RAOD",
    puneAundh:"PUNE-AUNDH",
    puneKharadi:"PUNE-KHARADI",
    puneNigdi:"PUNE-NIGDI(PIMPRI)",
    puneViman:"PUNE-VIMAN NAGAR",
    puneWanouri:"PUNE-WANOURI",
    keralaVelacherry:"VELACHERRY",
    apVizag:"VISHAKAPATNAM-VIZAG"
  },
  french: {
    helpUsFight:"Aidez-nous à combattre",
    welcomeTo:"Bienvenue à",
    againstCovid19:"Contre le Covid-19",
    swaasaRespiratoryAssessment:"Évaluation respiratoire Swaasa",
    introducing:"Présentation",
    swaasaCoughScanner:"Scanner contre la toux Swaasa",
    swaasaPlatform:"La plateforme Swaasa analyse votre bruit de toux pour évaluer votre santé respiratoire.",
    letsGetStarted:"Commençons",
    takingSelfAssessment:"L'auto-évaluation à la maison est pour votre propre bien-être. Il est donc important que vous suiviez attentivement les instructions pour obtenir de véritables résultats.",
    inTheInterest:"Dans l'intérêt de votre propre santé et de la santé de notre famille et collègues, il est nécessaire que l'échantillon de toux enregistré soit le vôtre la toux et pas celle d'une autre personne.",
    keepInMind:"Gardez à l'esprit que l'utilité et l'efficacité du système dépendent sur les données soumises et les réponses correctes et la toux authentique vos propres échantillons sont ce qui vous apportera le plus d'avantages à vous et aux membres de votre famille.",
    swaasaAISeriousInfections:"Swaasa AI est unique dans son processus de dépistage car il évalue la santé respiratoire à l'aide de l'audiométrie analyse des bruits de toux.Souvent, les symptômes du Covid-19 sont liés à la fonction respiratoire,faire le Covid-19,principalement une maladie respiratoire. Évaluez régulièrement votre santé respiratoire tout en nous aidant également à améliorer notre plate-forme qui utilise les sons de la toux, les symptômes et d'autres informations contextuelles pour dépister d'éventuelles infections au COVID-19 et d'autres infections graves.",
    swaasaAIRespiratoryIssue:"Swaasa AI est unique dans son processus de dépistage car il évalue la santé respiratoire à l'aide de l'audiométrie analyse des bruits de toux.Le Covid-19 affecte principalement le système respiratoire.Swaasa utilise une analyse de la toux basée sur l'IA pour évaluer la gravité de votre toux et fournir une indication de tout problème respiratoire",
    byContinuing:"En continuant, vous acceptez les conditions d'utilisation de Swaasa et",
    privacyPolicy:"Politique de confidentialité",
    beforeWeGoAnyFurther:"Avant d'aller plus loin…",
    pleaseEnter:"Veuillez entrer ce qui suit pour continuer :",
    otp:"OTP",
    sendOTP:"envoyer OTP",
    resendOTP:"Renvoyer OTP",
    someTips:"Quelques conseils à garder à l'esprit lors de l'enregistrement:",
    findQuietPlace:"Trouvez un endroit calme. Assurez-vous lors de l'enregistrement qu'il n'y a pas de bruit de fond comme le bruit du vent, le ventilateur et d'autres bruits de ce type.",
    holdThePhone:"Tenez le téléphone à 4 à 8 pouces de votre bouche.",
    aRecording:"Un enregistrement de 10 secondes aura un nombre adéquat de séquences de toux:-",
    pleaseCough:"Veuillez tousser plusieurs fois.",
    eachTime:"A chaque fois, respire et tousse.",
    repeatThis:"Répétez cette opération au moins 2 à 3 fois.",
    postRecording:"Après l'enregistrement, essuyez le téléphone avec un chiffon doux imbibé de désinfectant.",
    sinceYoure:"Puisque vous êtes ici pour la première fois..",
    pleaseEnterFollowing:"Veuillez saisir les informations suivantes pour commencer :",
    male:"Mâle",
    female:"Femelle",
    continue:"Continuer",
    bangaloreBasavanagudi:"BANGALORE-BASAVANAGUDI",
    bangaloreBellandur:"BANGALORE-BELLANDUR",
    bangaloreElectroniccity:"BANGALORE-ELECTRONIC CITY",
    bangaloreHsrlayout:"BANGALORE-HSR LAYOUT",
    bangaloreIndra:"BANGALORE-INDIRANAGAR",
    bangaloreJpnagar:"BANGALORE-JPNAGAR",
    bangaloreKormangala:"BANGALORE-KORAMANGALA",
    bangaloreMarathalli:"BANGALORE-MARATHAHALLI",
    bangaloreSarjapur:"BANGALORE-SARJAPUR ROAD",
    chennaiAnnanagar:"CHENNAI-ANNA NAGAR",
    chennaiKotturpuram:"CHENNAI-KOTTURPURAM",
    chennaiTnagar:"CHENNAI-T'NAGAR",
    chennaiValsara:"CHENNAI-VALASARAVAKKAM",
    ghaziIndra:"GHAZIABAD-INDIRAPURAM",
    hydAs:"HYD-A.S. RAO NAGAR",
    hydChand:"HYD-CHANDA NAGAR",
    hydKondapur:"HYD-KONDAPUR",
    hydManikonda:"HYD-MANIKONDA",
    hydNizam:"HYD-NIZAMPET",
    hydUppal:"HYD-UPPAL",
    karMysore:"MYSORE-KALIDASA RAOD",
    puneAundh:"PUNE-AUNDH",
    puneKharadi:"PUNE-KHARADI",
    puneNigdi:"PUNE-NIGDI(PIMPRI)",
    puneViman:"PUNE-VIMAN NAGAR",
    puneWanouri:"PUNE-WANOURI",
    keralaVelacherry:"VELACHERRY",
    apVizag:"VISHAKAPATNAM-VIZAG"
  },
  hindi: {
    helpUsFight:"लड़ने में हमारी मदद करें",
    welcomeTo:"में स्वागत",
    againstCovid19:"कोविड-19 के खिलाफ",
    swaasaRespiratoryAssessment:"स्वासा रेस्पिरेटरी असेसमेंट",
    introducing:"परिचय",
    swaasaCoughScanner:"स्वासा कफ स्कैनर",
    swaasaPlatform:"स्वासा प्लेटफॉर्म आपके श्वसन स्वास्थ्य का आकलन करने के लिए आपकी खांसी की आवाज का विश्लेषण करता है",
    letsGetStarted:"आएँ शुरू करें",
    takingSelfAssessment:"घर पर सेल्फ असेसमेंट लेना आपकी ही भलाई के लिए है।इसलिए यह महत्वपूर्ण है कि आप निर्देशों का सावधानीपूर्वक पालन करें वास्तविक परिणाम प्राप्त करें।",
    inTheInterest:"आपके अपने स्वास्थ्य और हमारे परिवार के स्वास्थ्य के हित में और साथियों यह आवश्यक है कि दर्ज की गई खांसी का नमूना आपका अपना हो खांसी और किसी अन्य व्यक्ति की नहीं",
    keepInMind:"ध्यान रखें कि सिस्टम की उपयोगिता और प्रभावशीलता निर्भर है सबमिट किए गए डेटा पर और सही उत्तर और प्रामाणिक खांसी अपने स्वयं के नमूने हैं जो आपको और आपके परिवार के सदस्यों को सबसे अधिक लाभ देंगे",
    swaasaAISeriousInfections:"स्वासा एआई अपनी स्क्रीनिंग प्रक्रिया में अद्वितीय है क्योंकि यह ऑडियोमेट्रिक का उपयोग करके श्वसन स्वास्थ्य का आकलन करता है खांसी की आवाज़ का विश्लेषण। अक्सर कोविड-19 के लक्षण श्वसन क्रिया से संबंधित होते हैं, कोविड -19 को मुख्य रूप से एक सांस की बीमारी बना रहा है।अपने श्वसन स्वास्थ्य का नियमित रूप से आकलन करें साथ ही हमें अपने प्लेटफॉर्म को बेहतर बनाने में भी मदद करते हैं जो संभावित COVID-19 और अन्य गंभीर संक्रमणों की जांच के लिए खांसी की आवाज़, लक्षण और अन्य प्रासंगिक जानकारी का उपयोग करता है।",
    swaasaAIRespiratoryIssue:"स्वासा एआई अपनी स्क्रीनिंग प्रक्रिया में अद्वितीय है क्योंकि यह ऑडियोमेट्रिक का उपयोग करके श्वसन स्वास्थ्य का आकलन करता है खांसी की आवाज़ का विश्लेषण।कोविड -19 मुख्य रूप से श्वसन प्रणाली को प्रभावित करता है।स्वासा आपकी खांसी की गंभीरता का आकलन करने और एक संकेत प्रदान करने के लिए एआई आधारित खांसी विश्लेषण का उपयोग करता है सांस की किसी भी समस्या से",
    byContinuing:"जारी रखकर आप स्वासा के उपयोग की शर्तों से सहमत होते हैं और",
    privacyPolicy:"गोपनीयता नीति",
    beforeWeGoAnyFurther:"इससे पहले कि हम और आगे बढ़ें…",
    pleaseEnter:"आगे बढ़ने के लिए कृपया निम्नलिखित दर्ज करें:",
    otp:"OTP",
    sendOTP:"भेजें ओटीपी",
    resendOTP:"ओटीपी पुनः भेजें",
    someTips:"रिकॉर्डिंग करते समय ध्यान रखने योग्य कुछ टिप्स:",
    findQuietPlace:"एक शांत जगह खोजें। सुनिश्चित करें कि रिकॉर्डिंग करते समय कोई पृष्ठभूमि शोर नहीं है जैसे हवा का शोर, पंखा और ऐसे अन्य शोर।.",
    holdThePhone:"फोन को अपने मुंह से 4-8 इंच दूर रखें।.",
    aRecording:"10 सेकंड की रिकॉर्डिंग में पर्याप्त संख्या में खांसी के क्रम होंगे:-",
    pleaseCough:"कृपया कई बार खांसी करें.",
    eachTime:"हर बार सांस लें और खांसें.",
    repeatThis:"इसे कम से कम 2 से 3 बार दोहराएं।.",
    postRecording:"रिकॉर्डिंग के बाद, फोन को कीटाणुनाशक में डूबे एक मुलायम कपड़े से पोंछ लें।.",
    sinceYoure:"चूंकि आप यहां पहली बार आए हैं..",
    pleaseEnterFollowing:"आरंभ करने के लिए कृपया निम्नलिखित दर्ज करें :",
    male:"पुरुष",
    female:"महिला",
    continue:"जारी रखें",
    bangaloreBasavanagudi:"BANGALORE-BASAVANAGUDI",
    bangaloreBellandur:"BANGALORE-BELLANDUR",
    bangaloreElectroniccity:"BANGALORE-ELECTRONIC CITY",
    bangaloreHsrlayout:"BANGALORE-HSR LAYOUT",
    bangaloreIndra:"BANGALORE-INDIRANAGAR",
    bangaloreJpnagar:"BANGALORE-JPNAGAR",
    bangaloreKormangala:"BANGALORE-KORAMANGALA",
    bangaloreMarathalli:"BANGALORE-MARATHAHALLI",
    bangaloreSarjapur:"BANGALORE-SARJAPUR ROAD",
    chennaiAnnanagar:"CHENNAI-ANNA NAGAR",
    chennaiKotturpuram:"CHENNAI-KOTTURPURAM",
    chennaiTnagar:"CHENNAI-T'NAGAR",
    chennaiValsara:"CHENNAI-VALASARAVAKKAM",
    ghaziIndra:"GHAZIABAD-INDIRAPURAM",
    hydAs:"HYD-A.S. RAO NAGAR",
    hydChand:"HYD-CHANDA NAGAR",
    hydKondapur:"HYD-KONDAPUR",
    hydManikonda:"HYD-MANIKONDA",
    hydNizam:"HYD-NIZAMPET",
    hydUppal:"HYD-UPPAL",
    karMysore:"MYSORE-KALIDASA RAOD",
    puneAundh:"PUNE-AUNDH",
    puneKharadi:"PUNE-KHARADI",
    puneNigdi:"PUNE-NIGDI(PIMPRI)",
    puneViman:"PUNE-VIMAN NAGAR",
    puneWanouri:"PUNE-WANOURI",
    keralaVelacherry:"VELACHERRY",
    apVizag:"VISHAKAPATNAM-VIZAG"
  },
  bengali: {
    helpUsFight:"আমাদের যুদ্ধ সাহায্য",
    welcomeTo:"স্বাগতম",
    againstCovid19:"কোভিড-১৯ এর বিরুদ্ধে",
    swaasaRespiratoryAssessment:"সোয়াসা শ্বাসযন্ত্রের মূল্যায়ন",
    introducing:"পরিচয় করিয়ে দিচ্ছে",
    swaasaCoughScanner:"সোয়াসা কাশি স্ক্যানার",
    swaasaPlatform:"সোয়াসা প্ল্যাটফর্ম আপনার শ্বাসযন্ত্রের স্বাস্থ্যের মূল্যায়ন করতে আপনার কাশির শব্দ বিশ্লেষণ করে।",
    letsGetStarted:"চল শুরু করি",
    takingSelfAssessment:"বাড়িতে স্ব-মূল্যায়ন করা আপনার নিজের মঙ্গলের জন্য। তাই সত্যিকারের ফলাফল পেতে আপনার নির্দেশাবলী সাবধানে অনুসরণ করা গুরুত্বপূর্ণ।",
    inTheInterest:"আপনার নিজের স্বাস্থ্য এবং আমাদের পরিবার এবং সহকর্মীদের স্বাস্থ্যের স্বার্থে এটি প্রয়োজন যে রেকর্ড করা কাশির নমুনাটি আপনার নিজের কাশি এবং অন্য কোনও ব্যক্তির নয়।",
    keepInMind:"মনে রাখবেন যে সিস্টেমের উপযোগিতা এবং কার্যকারিতা জমা দেওয়া ডেটা এবং সঠিক উত্তর এবং আপনার নিজস্ব খাঁটি কাশির নমুনার উপর নির্ভর করে যা আপনাকে এবং আপনার পরিবারের সদস্যদের সবচেয়ে বেশি সুবিধা দেবে।",
    swaasaAISeriousInfections:"সোয়াসা এআই এর স্ক্রীনিং প্রক্রিয়ায় অনন্য কারণ এটি কাশির শব্দের অডিওমেট্রিক বিশ্লেষণ ব্যবহার করে শ্বাসযন্ত্রের স্বাস্থ্যের মূল্যায়ন করে। প্রায়শই কোভিড-১৯-এর লক্ষণগুলি শ্বাসযন্ত্রের ক্রিয়াকলাপের সাথে সম্পর্কিত হয়, যা কোভিড-১৯-কে প্রধানত শ্বাসযন্ত্রের রোগে পরিণত করে। নিয়মিতভাবে আপনার শ্বাস-প্রশ্বাসের স্বাস্থ্যের মূল্যায়ন করুন এবং আমাদের প্ল্যাটফর্ম উন্নত করতে সাহায্য করুন যা সম্ভাব্য COVID-19 এবং অন্যান্য গুরুতর সংক্রমণের জন্য স্ক্রীন করার জন্য কাশির শব্দ, লক্ষণ এবং অন্যান্য প্রাসঙ্গিক তথ্য ব্যবহার করে।",
    swaasaAIRespiratoryIssue:"সোয়াসা এআই এর স্ক্রীনিং প্রক্রিয়ায় অনন্য কারণ এটি কাশির শব্দের অডিওমেট্রিক বিশ্লেষণ ব্যবহার করে শ্বাসযন্ত্রের স্বাস্থ্যের মূল্যায়ন করে। Covid-19 প্রধানত শ্বাসযন্ত্রের সিস্টেমকে প্রভাবিত করে। আপনার কাশির তীব্রতা নির্ণয় করতে এবং শ্বাসকষ্টজনিত সমস্যার ইঙ্গিত দিতে সোয়াসা AI ভিত্তিক কাশি বিশ্লেষণ ব্যবহার করে।",
    byContinuing:"চালিয়ে যাওয়ার মাধ্যমে আপনি Swaasa-এর ব্যবহারের শর্তাবলীতে সম্মত হন এবং",
    privacyPolicy:"গোপনীয়তা নীতি",
    beforeWeGoAnyFurther:"আমরা আরও এগিয়ে যাওয়ার আগে…",
    pleaseEnter:"এগিয়ে যেতে নিম্নলিখিত লিখুন অনুগ্রহ করে:",
    otp:"ওটিপি",
    sendOTP:"OTP পাঠান",
    resendOTP:"OTP আবার পাঠান",
    someTips:"রেকর্ড করার সময় কিছু টিপস মনে রাখবেন:",
    findQuietPlace:"একটি শান্ত জায়গা খুঁজুন. রেকর্ড করার সময় নিশ্চিত করুন যে বাতাসের শব্দ, ফ্যান এবং এই জাতীয় অন্যান্য শব্দের মতো কোনও পটভূমির শব্দ নেই।",
    holdThePhone:"আপনার মুখ থেকে ফোনটি 4-8 ইঞ্চি ধরে রাখুন।",
    aRecording:"10 সেকেন্ডের একটি রেকর্ডিংয়ে পর্যাপ্ত সংখ্যক কাশির ক্রম থাকবে:-",
    pleaseCough:"অনুগ্রহ করে একাধিকবার কাশি।",
    eachTime:"প্রতিবার শ্বাস নিন এবং কাশি নিন।",
    repeatThis:"এটি কমপক্ষে 2 থেকে 3 বার পুনরাবৃত্তি করুন।",
    postRecording:"রেকর্ডিং-এর পরে, জীবাণুনাশক ডোবানো নরম কাপড় দিয়ে ফোনটি মুছুন।",
    sinceYoure:"যেহেতু আপনি প্রথমবার এখানে এসেছেন..",
    pleaseEnterFollowing:"শুরু করতে অনুগ্রহ করে নিম্নলিখিতটি লিখুন:",
    male:"পুরুষ",
    female:"মহিলা",
    continue:"চালিয়ে যান",
    bangaloreBasavanagudi:"BANGALORE-BASAVANAGUDI",
    bangaloreBellandur:"BANGALORE-BELLANDUR",
    bangaloreElectroniccity:"BANGALORE-ELECTRONIC CITY",
    bangaloreHsrlayout:"BANGALORE-HSR LAYOUT",
    bangaloreIndra:"BANGALORE-INDIRANAGAR",
    bangaloreJpnagar:"BANGALORE-JPNAGAR",
    bangaloreKormangala:"BANGALORE-KORAMANGALA",
    bangaloreMarathalli:"BANGALORE-MARATHAHALLI",
    bangaloreSarjapur:"BANGALORE-SARJAPUR ROAD",
    chennaiAnnanagar:"CHENNAI-ANNA NAGAR",
    chennaiKotturpuram:"CHENNAI-KOTTURPURAM",
    chennaiTnagar:"CHENNAI-T'NAGAR",
    chennaiValsara:"CHENNAI-VALASARAVAKKAM",
    ghaziIndra:"GHAZIABAD-INDIRAPURAM",
    hydAs:"HYD-A.S. RAO NAGAR",
    hydChand:"HYD-CHANDA NAGAR",
    hydKondapur:"HYD-KONDAPUR",
    hydManikonda:"HYD-MANIKONDA",
    hydNizam:"HYD-NIZAMPET",
    hydUppal:"HYD-UPPAL",
    karMysore:"MYSORE-KALIDASA RAOD",
    puneAundh:"PUNE-AUNDH",
    puneKharadi:"PUNE-KHARADI",
    puneNigdi:"PUNE-NIGDI(PIMPRI)",
    puneViman:"PUNE-VIMAN NAGAR",
    puneWanouri:"PUNE-WANOURI",
    keralaVelacherry:"VELACHERRY",
    apVizag:"VISHAKAPATNAM-VIZAG"
  },
};
